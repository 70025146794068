<template>
  <header class="flex-shrink-0 relative h-16 bg-white flex items-center  font-SourceHanSerifCN">
    <!-- Logo area -->
    <!-- <div class="absolute inset-y-0 left-0 md:static md:flex-shrink-0">
        <a href="#" class="flex items-center justify-center h-16 w-16 bg-indigo-500 ">
          <img class="h-8 w-auto" src="@/assets/logo.png" alt="avatar" />
        </a>
      </div> -->
    <!-- Desktop nav area -->
    <div class="text-indigo-800 antialiased px-5 font-medium text-lg">{{accountInfo.intro}}</div>
    <div class="hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <div class="max-w-2xl relative text-gray-400 focus-within:text-gray-500">

        </div>
      </div>
      <div class="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">

        <div class="flex items-center space-x-8">
          <span class="inline-flex">
            <a href="#" class="-mx-1 bg-white p-1 rounded-full text-gray-400 hover:text-gray-500">
              <span class="sr-only">通知</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </a>
          </span>

          <Menu as="div" class="relative inline-block text-left">
            <MenuButton class="bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600">
              <!-- <span  class="sr-only">Open user menu</span> -->
              <div class="text-indigo-800 antialiased px-5 font-black">{{user.nickname}}</div>
              <img class="h-8 w-8 rounded-full" :src="user.avatar" alt="" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-3">
                  <!-- <MenuItem v-slot="{ active }">
                      <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                        修改密码
                      </a>
                    </MenuItem> -->
                  <MenuItem v-slot="{ active }">
                  <a @click="logout" href="#" :class="[active ? 'bg-indigo-300 text-black' : '', 'block px-4 py-2 text-sm ']">
                    登出
                  </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'

import {
  BellIcon
} from '@heroicons/vue/outline'

const isPlainObject = target =>
  target &&
  target.toString() === '[object Object]' &&
  Object.getPrototypeOf(target) === Object.prototype
const _jsonify = target => {
  if (target && typeof target.toFullJSON === 'function') return target.toFullJSON()
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    BellIcon
  },
  name: 'global-header',
  setup () {
    const router = useRouter()
    const store = useStore()
    const logout = () => {
      AV.User.logOut()
      router.push({ name: 'Login' })
    }
    const state = reactive({
      accountInfo: {}
    })

    onMounted(async () => {
      state.accountInfo = _jsonify(await new AV.Query('HLAccount').equalTo('user', AV.User.current()).first())
      store.state.accountInfo = state.accountInfo
    })

    return {
      logout,

      user: AV.User.current() ? AV.User.current().toJSON() : {},
      ...toRefs(state)
    }
  }
})
</script>
