import { createStore } from 'vuex'

export default createStore({
  state: {
    accountInfo: {},
    allQuestionType: [],
    allLevels: []
  },
  mutations: {

  },
  actions: {

  },
  modules: {
  }
})
