
import { defineComponent, reactive, toRefs, ref } from 'vue'
import AV from 'leancloud-storage'

import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Login',
  props: {

  },
  components: {
  },
  setup () {
    const router = useRouter()
    const SuccessDialog = ref()
    const ErrorDialog = ref()
    const state = reactive({
      errorTitle: '提示',
      errorContent: '账号密码错误',
      loginFormData: {
        account: '',
        password: ''
      },
      requesting: false

    })

    // 模拟延时
    // const delay = async (ms = 200) => {
    //   await new Promise((r) => setTimeout(r, ms))
    // }

    const doneAndNext = () => {
      SuccessDialog.value.hide()
      state.requesting = false
      console.log('点击进入下一步')
      router.push('/')
    }

    const errorDoneAndNext = () => {
      ErrorDialog.value.hide()
    }
    const handleSubmit = (e: any) => {
      e.preventDefault()

      doLogin()
    }

    // 点击登录
    const doLogin = async () => {
      // console.log('doLogin')
      {
        // 判断是否为移动端
        const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (flag) {
          state.errorTitle = '提示'
          state.errorContent = '仅在PC端可用'
          ErrorDialog.value.show()
          return
        }
      }

      state.requesting = true
      AV.User.logIn(state.loginFormData.account, state.loginFormData.password).then((user) => {
        SuccessDialog.value.show()
        state.requesting = false
      }, (error) => {
        console.log(error)
        state.requesting = false
        //   console.log('弹出错误')
        ErrorDialog.value.show()
      })
    }

    return {
      handleSubmit,
      SuccessDialog,
      ErrorDialog,
      doneAndNext,
      errorDoneAndNext,
      ...toRefs(state)
    }
  }
})
