<template>
    <error-dialog
    ref="ErrorDialog"
    title="抱歉"
    content="操作失败"
    confirmText="知道了"
    :doneAndNext="errorDoneAndNext"
  ></error-dialog>
  <success-dialog  ref="SuccessDialog" title="恭喜你" content="操作成功" confirmText="知道了" :doneAndNext="doneAndNext"></success-dialog>

  <nav aria-label="Progress" class="px-10 py-1">
    <ol role="list" class=" md:flex md:space-y-0 md:space-x-8">
      <li v-for="(step,index) in steps" :key="step.name" class="md:flex-1">
        <div>
          <div v-if="step.status === 'complete'" class="
            group
            pl-4
            py-2
            flex flex-col
            border-l-4 border-indigo-600
            hover:border-indigo-800
            md:pl-0
            md:pt-4
            md:pb-0
            md:border-l-0 md:border-t-4

          ">
            <span class="
              text-xs text-indigo-600
              font-semibold
              tracking-wide
              uppercase
              group-hover:text-indigo-800
            ">{{ step.id }}</span>
            <span class="text-sm font-medium">{{ step.name }}</span>
            <span class="text-xs font-medium text-gray-400 py-3 h-16">{{
            step.description
          }}</span>

          </div>
          <div v-else-if="step.status === 'current'" class="
            pl-4
            py-2
            flex flex-col
            border-l-4 border-indigo-600
            md:pl-0
            md:pt-4
            md:pb-0
            md:border-l-0 md:border-t-4
          " aria-current="step">
            <span class="
              text-xs text-indigo-600
              font-semibold
              tracking-wide
              uppercase
            ">{{ step.id }}</span>
            <span class="text-sm font-medium">{{ step.name }}</span>
            <span class="text-xs font-medium text-gray-400 py-3  h-16">{{
            step.description
          }}</span>
          </div>
          <div v-else class="
            group
            pl-4
            py-2
            flex flex-col
            border-l-4 border-gray-200
            hover:border-gray-300
            md:pl-0
            md:pt-4
            md:pb-0
            md:border-l-0 md:border-t-4
          ">
            <span class="
              text-xs text-gray-500
              font-semibold
              tracking-wide
              uppercase
              group-hover:text-gray-700
            ">{{ step.id }}</span>
            <span class="text-sm font-medium">{{ step.name }}</span>
            <span class="text-xs font-medium text-gray-400 py-3  h-16">{{
            step.description
          }}</span>
          </div>
        </div>

        <button v-if="step.canEdit" @click="openDialog(step)" type="button" class="
        inline-flex
        items-center
        px-2.5
        py-1.5
        border border-transparent
        text-xs
        font-medium
        rounded
        shadow-sm
        text-white
        bg-indigo-500
        hover:bg-indigo-700
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        animate__animated animate__fadeIn
      " >
          {{currentStepIndex== index?'正在编辑':'编辑'}}
        </button>
      </li>
    </ol>
  </nav>

  <!-- 题干部分 -->
  <div class="px-10 py-1 w-full h-3/4 mr-20 flex flex-row">
    <div class="w-1/4 h-full  border-t-4 border-indigo-600    overflow-scroll scrollbar scrollbar-thumb-indigo-600 scrollbar-track-gray-100  ">
      <div class="w-full flex flex-col pr-5 pb-10">
        <div v-for="(stem,index) in stems" :key="stem.sn" class=" mb-10 mt-2  flex flex-col rounded-lg shadow-lg my-3      w-full  relative">

          <p class="absolute font-bold  italic rounded bg-indigo-500 text-white px-3 rounded-r-none rounded-b-none">
            {{stem.sn}}
          </p>
          <p @click="edit(index,0)" class="  {{currentStemIndex == index?'animate-pulse' :''}} absolute rounded bg-red-500 text-white px-3  rounded-l-none rounded-b-none right-0">
            {{currentStemIndex == index && currentStepIndex==0?'正在编辑' :'编辑'}}
          </p>

          <div class="px-2 my-6 h-30 w-full ">
            {{stem.intro}}
          </div>
          <div class="min-h-60 flex-col flex content-center">
            <img class="px-2 w-full   rendering-crisp-edges  object-cover" :src="stem.image.url" alt="" />
            <div class="px-2 w-full py-10">
              <audio v-if="stem.voice.url" class="w-60" controls>
                <source :src="stem.voice.url" type="audio/mpeg">
                {{stem.voice.url}}
              </audio>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- 设问部分 -->

    <div v-if="steps[1].canEdit" class="w-1/4 h-full ml-5  border-t-4 border-indigo-600    overflow-scroll scrollbar scrollbar-thumb-indigo-600 scrollbar-track-gray-100  ">

      <div v-if="choices.length==0">
        <button @click="createChoice" type="button" class="
        my-2.5
        mx-2
        inline-flex
        items-center
        px-2.5
        py-1.5
        border border-transparent
        text-xs
        font-medium
        rounded
        shadow-sm
        text-white
        bg-indigo-500
        hover:bg-indigo-700
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        animate__animated animate__fadeIn
      ">
          新增
        </button>
      </div>
      <div class="w-full flex flex-col pr-5 pb-10">
        <div v-for="(choice,index) in choices" :key="choice.sn" class=" mb-10 mt-2  flex flex-col rounded-lg shadow-lg my-3      w-full  relative">

          <p class="absolute font-bold  italic rounded bg-indigo-500 text-white px-3 rounded-r-none rounded-b-none">
            {{choice.sn}}
          </p>
          <p @click="edit(index,1)" class="  {{currentChoiceIndex == index?'animate-pulse' :''}} absolute rounded bg-red-500 text-white px-3  rounded-l-none rounded-b-none right-0">
            {{currentChoiceIndex == index && currentStepIndex==1?'正在编辑' :'编辑'}}
          </p>

          <div class="px-2 my-6 h-30 w-full ">
            {{choice.intro}}
          </div>
          <div class="min-h-60 flex-col flex content-center">
            <img class="px-2 w-full   rendering-crisp-edges  object-cover" :src="choice.image.url" alt="" />
            <div class="px-2 w-full py-10">
              <audio v-if="choice.voice.url" class="w-60" controls>
                <source :src="choice.voice.url" type="audio/mpeg">
                {{choice.voice.url}}
              </audio>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!--正确答案部分 -->

    <div v-if="steps[2].canEdit" class="w-1/4 h-full ml-10  border-t-4 border-indigo-600    overflow-scroll scrollbar scrollbar-thumb-indigo-600 scrollbar-track-gray-100  ">
      <div class="w-full flex flex-col pr-5 pb-10">
        <div v-if="answerChoice.sn>-1" class=" w-full  break-words ">
          <div class=" mb-10 mt-2  flex flex-col rounded-lg shadow-lg my-3      w-full  relative">

            <p class="absolute font-bold  italic rounded bg-green-500 text-white px-3 rounded-r-none rounded-b-none">
              {{answerChoice.sn}}
            </p>
            <p class=" absolute rounded bg-green-500 text-white px-3  rounded-l-none rounded-b-none right-0">
              正确答案
            </p>

            <div class="px-2 my-6 h-30 w-full ">
              {{answerChoice.intro}}
            </div>
            <div class="min-h-60 flex-col flex content-center">
              <img class="px-2 w-full   rendering-crisp-edges  object-cover" :src="answerChoice.image.url" alt="" />
              <div class="px-2 w-full py-10">
                <audio v-if="answerChoice.voice.url" class="w-60" controls>
                  <source :src="answerChoice.voice.url" type="audio/mpeg">
                  {{answerChoice.voice.url}}
                </audio>
              </div>
            </div>

          </div>
        </div>
        <div v-if="choices.length==0" class=" w-full mt-10 break-words ">
          <span class="text-indigo-700 text-lg"> {{standardAnswerText}}</span>

        </div>
      </div>
    </div>
    <!-- 其他设置   -->
    <div v-if="steps[3].canEdit" class="w-1/4 h-full ml-5  border-t-4 border-indigo-600    overflow-scroll scrollbar scrollbar-thumb-indigo-600 scrollbar-track-gray-100  ">

      <div class="w-full flex flex-col pr-5 pb-10">
        <div class="flex flex-row  text-lg pb-5  text-indigo-600">
          <div>题型:</div>
          <div class="px-10">{{selectedQuestionType.name}}</div>
        </div>

        <div class="flex flex-row pb-5 text-lg text-indigo-600">
          <div>等级:</div>
          <div class="px-10">{{selectedLevel.level.level}}-{{selectedGrade.name}}</div>
        </div>

        <div class="flex flex-row pb-5 text-lg text-indigo-600">
          <div>课堂:</div>
          <div class="px-10">{{selectedGrade.showName}}</div>
        </div>
        <div class="flex flex-row pb-5 text-lg text-indigo-600">
          <div>天数:</div>
          <div class="px-10">{{selectedDay}}</div>
        </div>
        <div class="flex flex-row pb-5 text-lg text-indigo-600">
          <div>排序:</div>
          <div class="px-10">{{indexInOneDay}}</div>
        </div>
      </div>

    </div>

  </div>

  <TransitionRoot as="template" v-if="editOrAddOpen" :show="editOrAddOpen">
    <Dialog as="div"  v-if=" editOrAddOpen"  auto-reopen="true" class="fixed inset-0 overflow-hidden w-1 ">
      <div class="absolute inset-0 overflow-hidden">
        <!-- <DialogOverlay class="absolute inset-0" /> -->
        <!-- -->
        <div class=" fixed inset-y-0 pl-16   flex font-HLFont  z-10 " :class="{'left-12':currentStepIndex>1,'right-0':currentStepIndex<=1} ">
          <TransitionChild as="template">
            <div class="w-screen max-w-md ">
              <div class="
                    h-full
                    divide-y divide-gray-200
                    flex flex-col
                    bg-white
                    shadow-xl
                  ">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle v-if="currentStepIndex<2" class="text-lg font-medium text-white">
                        {{currentStepIndex==0?'题干':'设问'}}
                        <span class="text-lg text-white italic">NO. {{currentStepIndex==0?currentStemIndex+1:currentChoiceIndex+1}}</span>
                      </DialogTitle>
                      <DialogTitle v-if="currentStepIndex==2" class="text-lg font-medium text-white">
                        设置正确答案
                      </DialogTitle>
                      <DialogTitle v-if="currentStepIndex==3" class="text-lg font-medium text-white">
                        设置其他选项
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="
                              bg-indigo-700
                              rounded-md
                              text-indigo-200
                              hover:text-white
                              focus:outline-none
                              focus:ring-2 focus:ring-white
                            " @click="editOrAddOpen = false">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div v-if="currentStepIndex<2" class="mt-1">
                      <p class="text-sm text-indigo-300">
                        一段文本或者一个图片或者一个语音。可以同时存在。
                      </p>

                    </div>
                  </div>
                  <div v-if="currentStepIndex<2" class="flex-1 flex flex-col justify-between">
                    <div class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label for="intro" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                            文本
                          </label>
                          <div class="mt-1">
                            <textarea v-if="currentStepIndex==0" v-model="stems[currentStemIndex].intro" class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500
                                  focus:border-indigo-500
                                  border-gray-300
                                  rounded-md
                                " />
                            <textarea v-if="currentStepIndex==1" name="intro" v-model="choices[currentChoiceIndex].intro" id="intro" class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500
                                  focus:border-indigo-500
                                  border-gray-300
                                  rounded-md
                                " />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="px-4 divide-y divide-gray-200 sm:px-6 py-6">
                      <div class="sm:col-span-6">
                        <label for="cover-image" class="block text-sm font-medium text-gray-700">
                          图片
                        </label>
                        <div class="
                              mt-1
                              flex flex-col justify-center content-center px-6
                              pt-5
                              pb-6
                              border-2 border-gray-300 border-dashed
                              rounded-md
                            ">

                          <div class="
                                    flex
                                    justify-center
                                    text-sm text-gray-600
                                  ">
                            <label for="image-upload" class="
                                      relative
                                      cursor-pointer
                                      bg-white
                                      rounded-md
                                      font-medium
                                      text-indigo-600
                                      hover:text-indigo-500
                                      focus-within:outline-none
                                      focus-within:ring-2
                                      focus-within:ring-offset-2
                                      focus-within:ring-indigo-500
                                    ">
                              <span>Upload a file</span>
                              <input id="image-upload" name="image-upload" type="file" @change="previewImageFile" accept="image/*" class="sr-only" />
                            </label>
                          </div>
                          <div class=" flex
                                    justify-center text-xs text-gray-500 pt-3 ">
                            JPG、PNG、GIF、JPEG up to 3MB
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="px-4 divide-y divide-gray-200 sm:px-6 py-6">
                      <div class="sm:col-span-6">
                        <label for="cover-audio" class="block text-sm font-medium text-gray-700">
                          音频
                        </label>
                        <div class="
                              mt-1
                              flex flex-col justify-center content-center px-6
                              pt-5
                              pb-6
                              border-2 border-gray-300 border-dashed
                              rounded-md
                            ">

                          <div class="
                                    flex
                                    justify-center
                                    text-sm text-gray-600
                                  ">
                            <label for="audio-upload" class="
                                      relative
                                      cursor-pointer
                                      bg-white
                                      rounded-md
                                      font-medium
                                      text-indigo-600
                                      hover:text-indigo-500
                                      focus-within:outline-none
                                      focus-within:ring-2
                                      focus-within:ring-offset-2
                                      focus-within:ring-indigo-500
                                    ">
                              <span>Upload a file</span>
                              <input id="audio-upload" name="audio-upload" type="file" @change="previewAudioFile" accept="audio/mp3" class="sr-only" />
                            </label>
                          </div>
                          <div class=" flex
                                    justify-center text-xs text-gray-500 pt-3 ">
                            MP3 up to 3MB
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 设置标准答案-->
                  <div v-if="currentStepIndex==2">

                    <div v-if="choices.length>0">
                      <SwitchGroup as="div" class="flex items-center justify-between px-10 py-10">
                        <span class="flex-grow flex flex-col">
                          <SwitchLabel as="span" class="text-sm font-medium text-indigo-900" passive>是否为选择题</SwitchLabel>
                          <SwitchDescription as="span" class="text-sm text-gray-500 py-5 ">选中高亮为选择题</SwitchDescription>
                          <SwitchDescription as="span" class="text-sm text-gray-500  ">取消选中灰色为顺序拼接题(填空题)</SwitchDescription>
                        </span>
                        <Switch v-model="isChoiceAnswer" :class="[isChoiceAnswer ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                          <span aria-hidden="true" :class="[isChoiceAnswer ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                        </Switch>
                      </SwitchGroup>

                      <RadioGroup v-if="isChoiceAnswer" v-model="answerChoice">

                        <div class="relative bg-white  -space-y-px">
                          <RadioGroupOption as="template" v-for="(choice,choiceIndex) in choices" :key="choice.sn" :value="choice" v-slot="{ checked, active }">
                            <div :class="[choiceIndex === 0 ? 'rounded-tl-md rounded-tr-md' : '', choiceIndex === choices.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none']">
                              <div class="flex items-center text-sm">
                                <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 rounded-full border flex items-center justify-center']" aria-hidden="true">
                                  <span class="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'ml-3 font-medium']">{{ choice.sn }}</RadioGroupLabel>
                              </div>
                              <RadioGroupDescription class="ml-6 pl-1 text-sm h-full">
                                <span :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'font-medium']">{{ choice.intro.length>30?choice.intro.substring(0,30)+'.....': choice.intro}}</span>
                              </RadioGroupDescription>

                            </div>
                          </RadioGroupOption>
                        </div>
                      </RadioGroup>

                      <div v-if="!isChoiceAnswer">

                        <div class="px-4 divide-y divide-gray-200 sm:px-6">
                          <div class="space-y-6 pt-6 pb-5">
                            <div>
                              <label for="intro" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                                请输入标准答案(Word|Word|Word)
                              </label>
                              <div class="mt-10">
                                <textarea name="standardAnswerText" v-model="standardAnswerText" id="standardAnswerText" class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500
                                  focus:border-indigo-500
                                  border-gray-300
                                  rounded-md
                                " />

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div v-if="choices.length==0" class="px-4 divide-y divide-gray-200 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label for="intro" class="
                                block
                                text-sm
                                mb-10
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                            请输入语音题的标准答案(<span class="text-red-700">务必输入英文符号!不要输入中文符号!</span>)
                          </label>
                          <div class="mt-1">
                            <textarea type="text" name="standardAnswerText" v-model="standardAnswerText" id="standardAnswerText" class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500
                                  focus:border-indigo-500
                                  border-gray-300
                                  rounded-md
                                " />

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- 设置其他配置-->
                  <div v-if="currentStepIndex==3">

                    <div class="flex-col flex  px-10 py-10 bg-indigo-200 ">
                      <Listbox as="div" v-model="selectedQuestionType" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm w-30 font-medium text-indigo-700 mb-10">
                          题型
                        </ListboxLabel>
                        <div class="mt-1 relative px-10 mb-10">
                          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              selectedQuestionType.name
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="questionType in allQuestionType" :key="questionType.objectId" :value="questionType" v-slot="{ active, selectedQuestionType }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedQuestionType ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ questionType.name }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                      <Listbox as="div" v-model="selectedLevel" class="flex-row flex items-center ">
                        <ListboxLabel class="text-sm font-medium text-indigo-700  mb-10">
                          等级
                        </ListboxLabel>
                        <div class="mt-1 relative px-10  mb-10">
                          <ListboxButton class="
              relative
              w-48
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">
                              {{ selectedLevel.level.level }}-{{
                selectedLevel.level.name
              }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="level in allLevels" :key="level.objectId" :value="level" v-slot="{ active, selectedLevel }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedLevel ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ level.level.level }}-{{ level.level.name }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>

                      <Listbox as="div" v-model="selectedGrade" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm font-medium text-indigo-700  mb-10">
                          课堂
                        </ListboxLabel>
                        <div class="mt-1 relative px-10  mb-10">
                          <ListboxButton class="
              relative
              w-48
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              selectedGrade.showName
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="grade in allGrades" :key="grade.objectId" :value="grade" v-slot="{ active, selectedGrade }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedGrade ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ grade.showName }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>

                      <Listbox as="div" v-model="selectedDay" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm font-medium text-indigo-700 mb-10">
                          天数
                        </ListboxLabel>
                        <div class="mt-1 relative px-10 mb-10">
                          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              selectedDay
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="day in allDays" :key="day" :value="day" v-slot="{ active, selectedDay }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedDay ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ day }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>

                      <Listbox as="div" v-model="indexInOneDay" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm font-medium text-indigo-700">
                          排序
                        </ListboxLabel>
                        <div class="mt-1 relative px-10  ">
                          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm

              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              indexInOneDay
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="day in indexInOneDays" :key="day" :value="day" v-slot="{ active, indexInOneDay }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      indexInOneDay ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ day }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>

                    </div>
                    <div v-if="currentStepIndex==3" class="mt-10">
                      <button  @click="deleteQuestion" class="
                        ml-4
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-red-600
                        hover:bg-red-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                      ">
                        删除本题(不可恢复)
                      </button>
                      <button @click="saveQuestion" class="
                        ml-4
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ">
                        保存本题到数据库
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="currentStepIndex<2" class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button @click="deleteOne" v-if="currentStepIndex==0? stems.length>0:true" type="button" class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium

                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ">
                    删除
                  </button>
                  <button @click="save" class="
                        ml-4
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ">
                    仅保存
                  </button>
                  <button @click="saveAndCreate" class="
                        ml-4
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ">
                    保存并新增
                  </button>
                </div>

              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
      <!-- <div v-if="currentStepIndex==2" class="absolute inset-0 overflow-hidden"></div>
       <div v-if="currentStepIndex==3" class="absolute inset-0 overflow-hidden"></div> -->
    </Dialog>
  </TransitionRoot>

</template>

<script>
import { computed, inject, ref, reactive, toRefs, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import AV from 'leancloud-storage'
import DayJS from 'dayjs'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions, Switch, SwitchDescription, SwitchGroup, SwitchLabel, Dialog, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, DialogTitle, TransitionChild, TransitionRoot
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { XIcon } from '@heroicons/vue/outline'
const allDays = []
for (let i = 1; i < 101; i++) {
  allDays.push(i)
}
const indexInOneDays = []
for (let i = 1; i < 101; i++) {
  indexInOneDays.push(i)
}
const isPlainObject = target =>
  target &&
  target.toString() === '[object Object]' &&
  Object.getPrototypeOf(target) === Object.prototype
const _jsonify = target => {
  if (target && typeof target.toFullJSON === 'function') return target.toFullJSON()
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}

// complete
const steps = [
  { id: 'Step 1', order: 0, name: '设置题干', href: '#', description: '题干可以是文字、图片、语音', status: 'current', canEdit: true },
  { id: 'Step 2', order: 1, name: '设置选项', href: '#', description: '选项可以是单选、多选、文本跟读、字词选择、字词组合', status: 'upcoming', canEdit: false },
  { id: 'Step 3', order: 2, name: '设置正确答案', href: '#', description: '答案可以是选项序号、文本、语音录入、字词组合', status: 'upcoming', canEdit: false },
  { id: 'Step 4', order: 3, name: '其他设置', href: '#', description: '设置题型、等级、天数等。以及删除、修改题目。', status: 'upcoming', canEdit: false }
]

export default {
  components: {
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    XIcon,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon

  },

  setup () {
    const store = useStore()
    const route = useRoute()

    const ErrorDialog = ref()
    const SuccessDialog = ref()
    const openDialog = (step) => {
      state.currentStepIndex = step.order
      state.editOrAddOpen = true
    }

    const state = reactive({
      questionId: '',
      question: {},
      editOrAddOpen: true,
      uploadImageUrl: '',
      uploadAudioUrl: '',
      allLevels: store.state.allLevels,
      allQuestionType: store.state.allQuestionType,

      selectedLevel: {
        level: {
          name: '',
          level: ''
        }
      },
      allGrades: [],
      selectedGrade: {
        showName: ''
      },
      selectedDay: 1,
      indexInOneDay: 1,
      selectedQuestionType: { name: '' },

      stems: [
        {
          sn: 1,
          intro: '',
          image: '',
          voice: ''
        }],
      choices: [
      ],
      isChoiceAnswer: true, // 是否为选择题
      answerChoice: {
        sn: -1,
        intro: '',
        image: '',
        voice: ''
      },
      currentStemIndex: 0, // 当前进度的排序
      currentChoiceIndex: 0,
      standardAnswerText: '',
      currentStepIndex: 0// 当前进度

    })

    state.questionId = route.query.questionId
    state.editType = route.query.editType

    onMounted(async () => {
      if (state.questionId.length > 0) {
        state.question = _jsonify(await new AV.Query('FECQuestion').include('questionType').include('grade').get(state.questionId))

        state.selectedLevel = state.allLevels.find(level => {
          const findIndex = level.grades.findIndex(grade => {
            console.log(grade.objectId, state.question.grade.objectId)
            // eslint-disable-next-line eqeqeq
            return grade.objectId == state.question.grade.objectId
          })
          if (findIndex > -1) {
            return level
          }
        })
        console.log(state.selectedLevel)

        state.selectedGrade = state.selectedLevel.grades.find(grade => {
          // eslint-disable-next-line eqeqeq
          if (grade.classify == state.question.grade.classify) {
            return grade
          }
        })

        state.selectedQuestionType = state.allQuestionType.find(questionType => {
          // eslint-disable-next-line eqeqeq
          if (questionType.objectId == state.question.questionType.objectId) {
            return questionType
          }
        })
        state.selectedDay = state.question.anyDay
        state.indexInOneDay = state.question.indexInOneDay
        state.currentStepIndex = 3
        state.standardAnswerText = state.question.standardAnswerText
        state.isChoiceAnswer = state.question.answerSelection.length > 0
        if (state.isChoiceAnswer) {
          state.answerChoice = state.question.answerSelection[0]
        }
        state.stems = state.question.questionData[0]
        state.choices = state.question.questionData[1]
        state.editOrAddOpen = false
      } else {
        state.selectedLevel = state.allLevels[3]
        state.allGrades = state.selectedLevel.grades
        state.selectedGrade = state.allGrades[0]

        state.selectedQuestionType = state.allQuestionType[0]

        state.allGrades = state.selectedLevel.grades
        state.selectedGrade = state.allGrades[0]
      }

      watchEffect(() => {
        // eslint-disable-next-line eqeqeq
        if (state.editOrAddOpen == false) {
          state.currentStepIndex = -1
        }

        steps[1].canEdit = state.stems.some(stem => {
          return validatedSetUp(stem)
        })
        steps[2].canEdit = state.stems.some(stem => {
          return validatedSetUp(stem)
        })
        steps[3].canEdit = state.stems.some(stem => {
          return validatedSetUp(stem)
        })

        steps.map(step => {
          // eslint-disable-next-line eqeqeq
          if (step.order == state.currentStepIndex) {
            step.status = 'current'
          } else if (step.order < state.currentStepIndex) {
            step.status = 'complete'
          } else {
            step.status = 'upcoming'
          }
        })
      })
    })
    const validatedSetUp = (obj) => {
      // eslint-disable-next-line eqeqeq
      if (obj.intro.length == 0 && obj.image == '' && obj.voice == '') {
        return false
      }
      return true
    }
    const createChoice = async () => {
      state.currentStepIndex = 1
      state.choices.push({
        sn: state.choices.length + 1,
        intro: '',
        image: '',
        voice: ''
      })
      state.editOrAddOpen = true
    }
    const deleteOne = async () => {
      // eslint-disable-next-line eqeqeq
      if (state.currentStepIndex == 0) {
        // console.log(state.currentStemIndex)

        state.stems.splice(state.currentStemIndex, 1)
        // 光标移到最后一个
        state.currentStemIndex = state.stems.length - 1

        // console.log(state.stems)
        for (let i = 0; i < state.stems.length; i++) {
          state.stems[i].sn = i + 1
        }
      } else {
        state.choices.splice(state.currentChoiceIndex, 1)
        // 光标移到最后一个
        if (state.choices.length > 0) {
          state.currentChoiceIndex = state.choices.length - 1

          // console.log(state.stems)
          for (let i = 0; i < state.choices.length; i++) {
            state.choices[i].sn = i + 1
          }
        } else {
          state.currentChoiceIndex = 0
          state.choices = []
          state.isChoiceAnswer = false
          state.standardAnswerText = ''
          state.editOrAddOpen = false
        }
      }
    }
    const edit = async (index, stepIndex) => {
      state.currentStepIndex = stepIndex
      // eslint-disable-next-line eqeqeq
      if (state.currentStepIndex == 0) {
        state.currentStemIndex = index
        state.editOrAddOpen = true
      } else {
        state.currentChoiceIndex = index
        state.editOrAddOpen = true
      }
    }
    const save = async () => {
      // eslint-disable-next-line eqeqeq
      if (state.currentStepIndex == 0) {
        if (validatedSetUp(state.stems[state.currentStemIndex])) {
          state.editOrAddOpen = false
        }
      } else {
        if (validatedSetUp(state.choices[state.currentChoiceIndex])) {
          state.editOrAddOpen = false
        }
      }
    }
    const saveAndCreate = async () => {
      // eslint-disable-next-line eqeqeq
      if (state.currentStepIndex == 0) {
        if (state.stems.every(stem => {
          return validatedSetUp(stem)
        })) {
          state.currentStemIndex++
          // state.intro = ''
          state.stems.push({
            sn: state.stems.length + 1,
            intro: '',
            image: '',
            voice: ''
          })
        }
      } else {
        if (state.choices.every(choice => {
          return validatedSetUp(choice)
        })) {
          state.currentChoiceIndex++
          // state.intro = ''
          state.choices.push({
            sn: state.choices.length + 1,
            intro: '',
            image: '',
            voice: ''
          })
        }
      }
    }

    const previewImageFile = async (event) => {
      const localFile = event.target.files[0]
      const fileName = localFile.name
      console.log(localFile, fileName)
      const file = new AV.File(AV.User.current().get('objectId') + '.jpg', localFile)
      await file.save()
      console.log(file.url())
      // state.uploadImageUrl = file.url()
      // eslint-disable-next-line eqeqeq
      if (state.currentStepIndex == 0) {
        // 题干
        state.stems[state.currentStemIndex].image = {}
        state.stems[state.currentStemIndex].image.name = fileName
        state.stems[state.currentStemIndex].image.url = file.url()
      } else {
        // 设问
        state.choices[state.currentChoiceIndex].image = {}
        state.choices[state.currentChoiceIndex].image.name = fileName
        state.choices[state.currentChoiceIndex].image.url = file.url()
      }
    }

    const previewAudioFile = async (event) => {
      // console.log(event.target.files[0])
      const localFile = event.target.files[0]
      const fileName = localFile.name
      const file = new AV.File(AV.User.current().get('objectId') + '.mp3', localFile)
      await file.save()
      console.log(file.url())
      // state.uploadAudioUrl = file.url()
      // eslint-disable-next-line eqeqeq
      if (state.currentStepIndex == 0) {
        // 题干
        state.stems[state.currentStemIndex].voice = {}
        state.stems[state.currentStemIndex].voice.name = fileName
        state.stems[state.currentStemIndex].voice.url = file.url()
      } else {
        // 设问
        state.choices[state.currentChoiceIndex].voice = {}
        state.choices[state.currentChoiceIndex].voice.name = fileName
        state.choices[state.currentChoiceIndex].voice.url = file.url()
      }
    }

    const saveQuestion = async () => {
      let question
      // eslint-disable-next-line eqeqeq
      if (state.editType == 'edit') {
        // 编辑
        question = AV.parseJSON(state.question)
        question.set('updatedUser', AV.User.current())
      } else {
        // 新增
        question = new AV.Object('FECQuestion')
        question.set('createdUser', AV.User.current())
        question.set('updatedUser', AV.User.current())
      }
      if (state.answerChoice.sn >= 1) {
        question.set('answerSelection', [state.answerChoice])
      } else {
        question.set('answerSelection', [])
      }

      question.set('anyDay', Number(state.selectedDay))
      question.set('indexInOneDay', Number(state.indexInOneDay))
      question.set('questionData', [state.stems, state.choices])
      question.set('questionType', AV.parseJSON(state.selectedQuestionType))
      question.set('grade', AV.parseJSON(state.selectedGrade))
      question.set('standardAnswerText', state.standardAnswerText)
      question.set('version', 2)
      question.set('validated', true)
      try {
        await question.save()
        console.log(question)
        const hasQuestioinTypeInGrade = await new AV.Query('NBECQuestionTypeInGrade').equalTo('anyDay', question.get('anyDay')).equalTo('questionType', question.get('questionType')).equalTo('grade', question.get('grade')).count() > 0
        // console.log('hasQuestioinTypeInGrade', hasQuestioinTypeInGrade)
        if (!hasQuestioinTypeInGrade) {
          const questionTypeInGrade = new AV.Object('NBECQuestionTypeInGrade')
          questionTypeInGrade.set('anyDay', question.get('anyDay'))
          questionTypeInGrade.set('questionType', question.get('questionType'))
          questionTypeInGrade.set('grade', question.get('grade'))
          await questionTypeInGrade.save()
        }
        state.editOrAddOpen = false
        SuccessDialog.value.show()
      } catch (e) {
        console.log(e)
        state.editOrAddOpen = false
        ErrorDialog.value.show()
      }
    }

    return {
      indexInOneDays,
      allDays,
      steps,
      saveQuestion,
      openDialog,
      edit,
      save,
      createChoice,
      deleteOne,
      saveAndCreate,
      previewImageFile,
      previewAudioFile,
      ...toRefs(state),
      errorDoneAndNext: () => {
        ErrorDialog.value.hide()
      },
      ErrorDialog,
      SuccessDialog,
      doneAndNext: () => {
        SuccessDialog.value.hide()
      }

    }
  }
}
</script>
