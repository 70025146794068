import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'
// import VueSmoothScroll from 'vue3-smooth-scroll'
// import './assets/tailwind.css' // Here
// import * as commonApi from './common'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import AV from 'leancloud-storage'

import SuccessDialog from '@/components/SuccessDialog.vue'
import GlobalHeader from '@/views/index/header.vue'
import GlobalLeft from '@/views/index/left.vue'
import ErrorDialog from '@/components/ErrorDialog.vue'

AV.init({
  appId: 'vF4UkeiM0SozsFEr3vjmKCNm-gzGzoHsz',
  appKey: 'jmUElL3YoHqUfFtkDroWbUca',
  serverURLs: 'https://api.hlingsoft.com'
})

const app = createApp(App)

app.use(store)
app.use(router)
app.use(Antd)
// app.use(VueSmoothScroll)

// app.use(commonApi)
app.component('SuccessDialog', SuccessDialog)
app.component('ErrorDialog', ErrorDialog)
app.component('GlobalHeader', GlobalHeader)
app.component('GlobalLeft', GlobalLeft)

app.mount('#app')
