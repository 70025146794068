<template >
  <!-- <success-dialog ></success-dialog> -->
   <error-dialog
    ref="ErrorDialog"
    :title="errorTitle"
    :content="errorContent"
    confirmText="知道了"
    :doneAndNext="errorDoneAndNext"
  ></error-dialog>
  <success-dialog  ref="SuccessDialog" title="恭喜你" content="登录成功" confirmText="进入主页" :doneAndNext="doneAndNext"></success-dialog>
  <section class="z-0 flex flex-col md:flex-row h-screen items-center">
    <div
      class="bg-indigo-600 hidden lg:block w-4/5 h-screen"
    >
      <img
        src="@/assets/login-bg.jpeg"
        alt=""
        class="w-full h-full object-cover"
      />
    </div>
    <div
      class="
        bg-white
        w-full
        md:max-w-md
        lg:max-w-full md:mx-0
        md:w-1/2
        xl:w-1/3
        h-screen
        px-6
        lg:px-16
        xl:px-12
        flex
        items-center
        justify-center
      "
    >
      <div class="w-full h-100">
        <h1 class="text-xl md:text-2xl font-bold leading-tight mt-2">System For NEWOAKBAY</h1>
        <h2 class="text-xl md:text-2xl font-bold leading-tight mt-2">
          Log in to your account
        </h2>

        <form class="mt-6" @submit="handleSubmit"  :model="loginFormData"  action="#" method="POST">
          <div>
            <label class="block text-gray-700">Account</label>
            <input
              name="accout"
              id="accout"
              v-model="loginFormData.account"
              placeholder="Enter Your Account"
              class="
                w-full
                px-4
                py-3
                rounded-lg
                bg-gray-200
                mt-2
                border
                focus:border-blue-500
                focus:bg-white
                focus:outline-none
              "
              autofocus
              autocomplete
              required
            />
          </div>

          <div class="mt-4 ">
            <label class="block text-gray-700">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              v-model="loginFormData.password"
              placeholder="Enter Password"
              minlength="6"
              class="
                w-full
                px-4
                py-3
                rounded-lg
                bg-gray-200
                mt-2
                border
                focus:border-blue-500
                focus:bg-white
                focus:outline-none
              "
              required
            />
          </div>

          <div class="text-right mt-2">
            <a
              href="#"
              class="
                text-sm
                font-semibold
                text-gray-700
                hover:text-blue-700
                focus:text-blue-700
              "
              >Forgot Password?</a
            >
          </div>
          <button
            type="submit"
            class="
              w-full
              block
              bg-indigo-500
              hover:bg-indigo-400
              focus:bg-indigo-400
              text-white
              font-semibold
              rounded-lg
              px-4
              py-3
              mt-6
            "
          >
          <span v-if="requesting" class="animate-spin inline-block py-1 px-2 bg-yellow-500   rounded-lg"></span>
          <span v-if="requesting"  class="text-yellow-500 px-4"> Requesting... </span>
          <span v-if="!requesting" > Log In</span>
          </button>
        </form>
        <hr class="my-6 border-gray-300 w-full" />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from 'vue'
import AV from 'leancloud-storage'

import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Login',
  props: {

  },
  components: {
  },
  setup () {
    const router = useRouter()
    const SuccessDialog = ref()
    const ErrorDialog = ref()
    const state = reactive({
      errorTitle: '提示',
      errorContent: '账号密码错误',
      loginFormData: {
        account: '',
        password: ''
      },
      requesting: false

    })

    // 模拟延时
    // const delay = async (ms = 200) => {
    //   await new Promise((r) => setTimeout(r, ms))
    // }

    const doneAndNext = () => {
      SuccessDialog.value.hide()
      state.requesting = false
      console.log('点击进入下一步')
      router.push('/')
    }

    const errorDoneAndNext = () => {
      ErrorDialog.value.hide()
    }
    const handleSubmit = (e: any) => {
      e.preventDefault()

      doLogin()
    }

    // 点击登录
    const doLogin = async () => {
      // console.log('doLogin')
      {
        // 判断是否为移动端
        const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (flag) {
          state.errorTitle = '提示'
          state.errorContent = '仅在PC端可用'
          ErrorDialog.value.show()
          return
        }
      }

      state.requesting = true
      AV.User.logIn(state.loginFormData.account, state.loginFormData.password).then((user) => {
        SuccessDialog.value.show()
        state.requesting = false
      }, (error) => {
        console.log(error)
        state.requesting = false
        //   console.log('弹出错误')
        ErrorDialog.value.show()
      })
    }

    return {
      handleSubmit,
      SuccessDialog,
      ErrorDialog,
      doneAndNext,
      errorDoneAndNext,
      ...toRefs(state)
    }
  }
})
</script>
