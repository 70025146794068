<template>
  <!-- <success-dialog  ref="SuccessDialog" title="恭喜你" content="登录成功" confirmText="进入主页" :doneAndNext="doneAndNext"></success-dialog> -->
  <!-- <error-dialog
    ref="ErrorDialog"
    :title="errorTitle"
    :content="errorContent"
    confirmText="知道了"
    :doneAndNext="errorDoneAndNext"
  ></error-dialog> -->
  <router-view v-if="routerName == 'Login'" v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
   <div v-if="routerName != 'Login'"  class="h-screen bg-gray-50 flex overflow-hidden">
    <!-- Narrow sidebar -->
   <global-left></global-left>

    <!-- Content area -->
    <div class="flex-1 flex flex-col overflow-hidden">

      <global-header></global-header>

      <!-- Main content -->
      <div class="flex-1 flex items-stretch overflow-hidden">
        <main class="flex-1 overflow-y-auto">
          <!-- Primary column -->
          <section aria-labelledby="primary-heading" class="min-w-0  bg-white flex-1 h-full flex flex-col overflow-hidden lg:order-last">

            <router-view v-slot="{ Component }">
                <component :is="Component" />
            </router-view>
          </section>
        </main>

        <!-- Secondary column (hidden on smaller screens) -->
        <!-- <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto lg:block">

        </aside> -->
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/tailwind.css'

import { ref, reactive, toRefs, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { ChevronDownIcon, SearchIcon } from '@heroicons/vue/solid'

import {

  BellIcon,

  MenuIcon,

  XIcon

} from '@heroicons/vue/outline'

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    ChevronDownIcon,
    MenuIcon,
    SearchIcon,
    XIcon
  },

  setup () {
    console.log('setup')
    const router = useRouter()
    const route = useRoute()

    // const ErrorDialog = ref()
    console.log(route)

    watch(() => route.name, () => {
      state.routerName = route.name
    }
    )

    const state = reactive({
      routerName: route.name,
      errorTitle: '温馨提示',
      errorContent: '暂未提供'

    })

    return {

      ...toRefs(state),
      route,
      router
      // errorDoneAndNext: () => {
      //   ErrorDialog.value.hide()
      // },
      // ErrorDialog

    }
  }
}
</script>
<style>
@font-face {
  font-family: "HL-Font";
  src: url("/SourceHanSerifCN-Bold.otf") format("OpenType")
}
#app {
  font-family: 'HL-Font';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
</style>
