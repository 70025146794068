<template>
   <div class="hidden w-28 bg-indigo-600 overflow-y-auto md:block">
      <div class="w-full py-3 flex flex-col items-center">
        <div class="flex-shrink-0 flex items-center">
          <img class="h-10 w-auto" src="@/assets/logo.png" alt="Workflow" />
        </div>
        <div class="flex-1 mt-6 w-full px-2 space-y-1 py-8">
          <a v-for="item in sidebarNavigation" :key="item.name"  @click="toView(item.routeName)"  :class="[item.current ? 'bg-indigo-800 text-white' : 'text-indigo-100 hover:bg-indigo-800 hover:text-white', 'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium']" :aria-current="item.current  ? 'page' : undefined">
            <component :is="item.icon" :class="[item.current  ? 'text-white' : 'text-indigo-300 group-hover:text-white', 'h-6 w-6']" aria-hidden="true" />
            <span class="mt-2">{{ item.name }}</span>
          </a>
        </div>
      </div>
    </div>
</template>

<script>
import { defineComponent, watch, reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {
  CollectionIcon,
  ViewGridIcon,
  UserGroupIcon,
  CurrencyYenIcon,
  CubeIcon

} from '@heroicons/vue/outline'

export default defineComponent({
  components: {

  },
  name: 'global-left',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      sidebarNavigation: [
        // { name: '题目管理', index: 0, href: '', icon: CollectionIcon, routeName: 'Home', pre: 'question', current: true },
        // { name: '题型管理', index: 1, href: '', icon: ViewGridIcon, pre: 'questionType', routeName: 'QuestionType', current: false },
        // { name: '等级管理', index: 2, href: '', icon: UserGroupIcon, pre: 'grade', routeName: 'Grade', current: false },
        { name: '经销商管理', index: 3, href: '', icon: CurrencyYenIcon, pre: 'dealer', routeName: 'Dealer', current: false },
        { name: '等级和题型关联关系管理', index: 4, href: '', icon: CubeIcon, pre: 'questionTypeAndGrade', routeName: 'QuestionAndGrade', current: false }
        //   { name: '用户管理', href: '#', icon: CollectionIcon, current: false }
      ]

    })

    watch(() => route.name, () => {
      state.sidebarNavigation.forEach(navigator => {
        console.log(navigator.pre, route.path)
        // eslint-disable-next-line eqeqeq
        if (route.path == '/' || route.path == '') {
          // eslint-disable-next-line eqeqeq
          if (navigator.index == 0) {
            navigator.current = true
          } else {
            navigator.current = false
          }
        } else {
          // eslint-disable-next-line eqeqeq
          if (route.path.split('/')[1] == navigator.pre || route.path.split('/')[0] == navigator.pre) {
            navigator.current = true
          } else {
            navigator.current = false
          }
        }
      })

      console.log('sidebarNavigation', state.sidebarNavigation)
    }
    )

    const toView = (routeName) => {
      router.push({ name: routeName })
    }

    return {
      route,
      router,
      toView,
      ...toRefs(state)

    }
  }
})
</script>
