<template>
  <error-dialog ref="ErrorDialog" title="抱歉" content="操作失败" confirmText="知道了"
    :doneAndNext="errorDoneAndNext"></error-dialog>
  <success-dialog ref="SuccessDialog" title="恭喜你" content="操作成功" confirmText="知道了"
    :doneAndNext="doneAndNext"></success-dialog>

  <div class="px-4  sm:px-6 lg:px-8">

    <div class="flex-row flex items-center px-4 py-4 bg-indigo-200  rounded">
      <button @click="toAdd" type="button" class=" inline-flex items-center px-2.5
                py-1.5
                border border-transparent
                text-xs
                font-medium
                rounded
                shadow-sm
                text-white
                bg-indigo-600
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              ">
        新增等级
      </button>
    </div>

    <a-table class="ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100" bordered
      :data-source="allLevels" :scroll="{ y: 360 }" :rowClassName="
        (record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
      ">
      <a-table-column class="w-20" key="objectId" title="编号" :ellipsis="true" , data-index="objectId" />
      <a-table-column key="indexInt" title="排序" data-index="indexInt" />
      <a-table-column key="level" title="所属级别" data-index="level" />
      <a-table-column key="name" title="名字" data-index="name" />
      <a-table-column key="classify" title="等级">
        <template #default="{ record }">
          <span v-if="record.classify == '0' || record.classify == 'initiation'"> 其他等级</span>
          <span v-if="record.classify == '1'"> 基础等级</span>
          <span v-if="record.classify == '2'"> 进阶等级</span>
          <span v-if="record.classify == '3'"> 精英等级</span>
          <!-- <a :href="record.voice" target="blank">{{record.voiceName}}</a> -->
        </template>
      </a-table-column>
      <a-table-column key="intro" title="备注" :ellipsis="true" data-index="intro" />
      <!--   <a-table-colum  key="intro" title="文本" class="w-30" :ellipsis="true" data-index="intro" />
        <a-table-column key="image" title="图片">
          <template #default="{ record }">
              <a :href="record.image" target="blank">{{record.imageName}}</a>
          </template>
        </a-table-column>
        -->

      <a-table-column key="action" title="操作">
        <template #default="{ record }">
          <span>

            <!-- <a-divider type="vertical" /> -->
            <a @click="editLevel(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="editQuestionType(record.objectId)">查看题型</a>
          </span>
        </template>
      </a-table-column>

      <template #footer>总共:<span class="text-indigo-600 px-2">{{ allLevels.length }}</span>条记录</template>
    </a-table>
  </div>

  <TransitionRoot as="template" v-if="editOrAddOpen" :show="editOrAddOpen">
    <Dialog as="div" id="editOrAdd" v-if="editOrAddOpen" static class=" font-HLFont  fixed inset-0 z-10 overflow-hidden"
      @close="editOrAddOpen = false">
      <div class="absolute inset-0 overflow-hidden ">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex  font-HLFont">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full" enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
            leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      {{ editOrAdd == 'edit' ? '编辑' : '新增' }}等级
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button"
                        class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        @click="editOrAddOpen = false">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-indigo-300">
                      请描述完整等级说明
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6 ">
                  <!-- Replace with your content -->

                  <div class="absolute inset-0 py-6 px-4 sm:px-6">

                    <div class="h-full border-dashed border-gray-200  py-5 px-5" aria-hidden="true">

                      <div>
                        <label for="name" class="
                                      block
                                      text-sm
                                      font-medium
                                      text-indigo-500
                                      group-hover:text-indigo-900
                                    ">
                          等级(Level 1 Level 2 ...)
                        </label>
                        <div class="mt-5">

                          <input id="level" type="text" name="level" v-model="currentLevel.level"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>
                      <div class="mt-5">
                        <label for="name" class="
                                      block
                                      text-sm
                                      font-medium
                                      text-indigo-500
                                      group-hover:text-indigo-900
                                    ">
                          名字(GK2 GK3 ...)
                        </label>
                        <div class="mt-5">

                          <input id="name" type="text" name="name" v-model="currentLevel.name"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <RadioGroup v-model="currentLevel.classify">

                        <div class="bg-white mt-5  space-y-px">
                          <RadioGroupOption as="template" v-for="(grade, gradeIndex) in grades" :key="grade.name"
                            :value="grade" v-slot="{ checked, active }">
                            <div
                              :class="[gradeIndex === 0 ? 'rounded-tl-md rounded-tr-md' : '', gradeIndex === grades.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                              <span
                                :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']"
                                aria-hidden="true">
                                <span class="rounded-full bg-white w-1.5 h-1.5" />
                              </span>
                              <div class="ml-3 flex flex-col">
                                <RadioGroupLabel as="span"
                                  :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                                  {{ grade.name }}
                                </RadioGroupLabel>

                              </div>
                            </div>
                          </RadioGroupOption>
                        </div>
                      </RadioGroup>

                      <div class="mt-5">
                        <label for="intro" class="
                                      block
                                      text-sm
                                      font-medium
                                      text-indigo-500
                                      group-hover:text-indigo-900
                                    ">
                          备注
                        </label>
                        <div class="mt-1">

                          <textarea id="intro" name="intro" v-model="currentLevel.intro"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="mt-1">

                        <label for="intro" class="
                                      block
                                      text-sm
                                      font-medium
                                      text-indigo-500
                                      group-hover:text-indigo-900
                                    ">
                          排序
                        </label>
                        <div class="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input type="number" name="indexInt" id="indexInt" v-model="currentLevel.indexInt"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 mt-10 border-t border-gray-200 py-5 sm:px-6">
                        <div class="space-x-3 flex justify-end">
                          <button @click="deleteThis" v-if="editOrAdd == 'edit'" type="button"
                            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            删除
                          </button>
                          <button @click="save"
                            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{ editOrAdd == 'edit' ? '保存' : '新增' }}
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- /End replace -->
                </div>
              </div>

            </div>

          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { computed, ref, reactive, toRefs, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import AV from 'leancloud-storage'
import { notification } from 'ant-design-vue'
import DayJS from 'dayjs'

import router from '@/router'

const isPlainObject = (target) =>
  target &&
  target.toString() === '[object Object]' &&
  Object.getPrototypeOf(target) === Object.prototype
const _jsonify = (target) => {
  if (target && typeof target.toFullJSON === 'function') { return target.toFullJSON() }
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    XIcon
  },

  setup () {
    const store = useStore()
    const ErrorDialog = ref()
    const SuccessDialog = ref()

    const state = reactive({
      allLevels: [],

      editOrAddOpen: false,
      editOrAdd: 'add',

      grades: [
        { name: '基础等级', classify: '1' },
        { name: '进阶等级', classify: '2' },
        { name: '精英等级', classify: '3' },
        { name: '其他等级', classify: '0' }
      ],
      currentLevel: {
        name: '',
        level: '',
        intro: '',
        indexInt: 0
      }

    })

    state.currentLevel.classify = state.grades[0]

    console.log(state.allLevels)

    onMounted(async () => {
      state.allLevels = _jsonify(await new AV.Query('FECGrade').equalTo('validated', true).ascending('indexInt').limit(1000).find())
      console.log(state.allLevels)
      //   watchEffect(() => {
      //     console.log(state.currentLevel)
      //   })
    })

    const toAdd = async () => {
      state.editOrAddOpen = true
      state.editOrAdd = 'add'
      state.currentLevel = {
        name: '',
        level: '',
        intro: '',
        indexInt: 0,
        classify: state.grades[0]
      }
    }

    const editLevel = async (level) => {
      state.editOrAddOpen = true
      state.editOrAdd = 'edit'
      console.log(level)

      state.currentLevel = level
      state.currentLevel.classify = state.grades.find(grade => {
        // eslint-disable-next-line eqeqeq
        if (level.classify == 'initiation') {
          // eslint-disable-next-line eqeqeq
          return grade.classify == 0
        }
        // eslint-disable-next-line eqeqeq
        return grade.classify == level.classify
      })
    }
    const save = async () => {
      console.log(state.currentLevel)
      // eslint-disable-next-line eqeqeq
      if (state.currentLevel.level.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '等级不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentLevel.name.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '名称不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentLevel.intro.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '备注不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentLevel.indexInt == 0) {
        notification.error({
          message: '错误',
          description: '排序不能小于等于0',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.editOrAdd == 'edit' && state.currentLevel.objectId) {
        // 编辑
        const level = AV.parseJSON(JSON.parse(JSON.stringify(state.currentLevel)))
        level.set('name', state.currentLevel.name)
        level.set('intro', state.currentLevel.intro)
        level.set('level', state.currentLevel.level)
        level.set('series', Number(state.currentLevel.level.split(' ')[1]))
        level.set('description', state.currentLevel.intro)
        level.set('index', state.currentLevel.indexInt + '')
        level.set('indexInt', Number(state.currentLevel.indexInt))
        level.set('classify', '' + state.currentLevel.classify.classify)
        try {
          await level.save()
          state.editOrAddOpen = false
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      } else {
        // 新增
        const level = new AV.Object('FECGrade')
        // level=state.currentLevel
        level.set('name', state.currentLevel.name)
        level.set('intro', state.currentLevel.intro)
        level.set('level', state.currentLevel.level)
        level.set('series', Number(state.currentLevel.level.split(' ')[1]))
        level.set('description', state.currentLevel.intro)
        level.set('index', state.currentLevel.indexInt + '')
        level.set('indexInt', Number(state.currentLevel.indexInt))
        level.set('classify', '' + state.currentLevel.classify.classify)
        level.set('validated', true)

        try {
          await level.save()
          state.allLevels.unshift(_jsonify(level))
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      }
    }

    return {

      toAdd,
      save,
      editLevel,
      ...toRefs(state),
      errorDoneAndNext: () => {
        ErrorDialog.value.hide()
      },
      ErrorDialog,
      SuccessDialog,
      doneAndNext: () => {
        SuccessDialog.value.hide()
      }

    }
  }
}
</script>
