/* eslint-disable eqeqeq */
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/index/index.vue'
import Grade from '../views/grade/index.vue'
import QuestionType from '../views/questionType/index.vue'
import Dealer from '../views/dealer/index.vue'
import DealerDetail from '../views/dealer/detail.vue'
import QuestionAndGrade from '../views/question/questionTypeAndGrade.vue'
import Login from '../views/unlogin/login.vue'
import QuestionAdd from '../views/question/add.vue'
import NotFound from '../views/error/notFound.vue'
// import TeacherInSystem from '../views/teacher/inSystem.vue'
import AV from 'leancloud-storage'
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/notFound',
      name: 'notFound',
      component: NotFound
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/grade',
      name: 'Grade',
      component: Grade
    },
    {
      path: '/questionType',
      name: 'QuestionType',
      component: QuestionType
    },

    {
      path: '/question/add',
      name: 'QuestionAdd',
      component: QuestionAdd
    },

    {
      path: '/dealer',
      name: 'Dealer',
      component: Dealer
    },

    {
      path: '/dealer/detail',
      name: 'DealerDetail',
      component: DealerDetail
    },

    {
      path: '/questionTypeAndGrade',
      name: 'QuestionAndGrade',
      component: QuestionAndGrade
    }
  ]
})
router.beforeEach((to, from, next) => {
  // next({ name: 'Login' })
  // console.log(AV.User.current())
  // router.push({ name: 'Login' })
  if ((to.name !== 'Login' && !AV.User.current())) {
    next({ name: 'Login' })
    // router.push({ name: 'Login' })
  } else next()
})
export default router
