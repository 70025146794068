<template>
  <error-dialog ref="ErrorDialog" title="抱歉" content="操作失败" confirmText="知道了" :doneAndNext="errorDoneAndNext"></error-dialog>
  <success-dialog ref="SuccessDialog" title="恭喜你" content="操作成功" confirmText="知道了" :doneAndNext="doneAndNext"></success-dialog>

  <div class="px-4  sm:px-6 lg:px-8">

    <div class="flex-row flex items-center px-4 py-4 bg-indigo-200  rounded">
      <button @click="toAdd" type="button" class=" inline-flex items-center px-2.5
          py-1.5
          border border-transparent
          text-xs
          font-medium
          rounded
          shadow-sm
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        ">
        新增经销商
      </button>
    </div>

    <a-table class="ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100" bordered :data-source="allDealer" :scroll="{ y: 360 }" :rowClassName="
        (record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
      ">
      <a-table-column class="w-20" key="objectId" title="编号" :ellipsis="true" , data-index="objectId" />

      <a-table-column key="name" title="名字" data-index="name" />

      <a-table-column key="phone" title="手机号码" data-index="phone" />
      <a-table-column key="intro" title="备注" :ellipsis="true" data-index="intro" />
      <!--   <a-table-colum  key="intro" title="文本" class="w-30" :ellipsis="true" data-index="intro" />
        <a-table-column key="image" title="图片">
          <template #default="{ record }">
              <a :href="record.image" target="blank">{{record.imageName}}</a>
          </template>
        </a-table-column>
        -->

      <a-table-column key="action" title="操作">
        <template #default="{ record }">
          <span>

            <a-divider type="vertical" />
            <a @click="editDealer(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="detail(record.objectId)">管理人员</a>
          </span>
        </template>
      </a-table-column>

      <template #footer>总共:<span class="text-indigo-600 px-2">{{allDealer.length}}</span>条记录</template>
    </a-table>
  </div>

  <TransitionRoot as="template" v-if="editOrAddOpen" :show="editOrAddOpen">
    <Dialog as="div" id="editOrAdd" v-if="editOrAddOpen" static class="  font-HLFont fixed inset-0 z-10 overflow-hidden" @close="editOrAddOpen = false">
      <div class="absolute inset-0 overflow-hidden ">
        <DialogOverlay class="absolute inset-0" />

        <div class=" font-HLFont fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      {{editOrAdd=='edit'?'编辑':'新增'}}经销商
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="editOrAddOpen = false">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-indigo-300">
                     请务必输入正确的姓名和手机号码
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->

                  <div class="absolute inset-0 py-6 px-4 sm:px-6">

                    <div class="h-full border-2 border-dashed border-gray-200 py-5 px-5" aria-hidden="true">

                      <div>
                        <label for="name" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                         姓名
                        </label>
                        <div class="mt-5">

                          <input id="name" type="text" name="name" v-model="currentDealer.name" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                         <div class="mt-10">
                        <label for="phone" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                         手机号码
                        </label>
                        <div class="mt-5">

                          <input id="phone" type="text" name="phone" v-model="currentDealer.phone" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="mt-10">
                        <label for="intro" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                          备注
                        </label>
                        <div class="mt-1">

                          <textarea id="intro" name="intro" v-model="currentDealer.intro" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 mt-10 border-t border-gray-200 py-5 sm:px-6">
                        <div class="space-x-3 flex justify-end">

                          <button @click="save" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{editOrAdd=='edit'?'保存':'新增'}}
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- /End replace -->
                </div>
              </div>

            </div>

          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import { computed, ref, reactive, toRefs, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import AV from 'leancloud-storage'
import { notification } from 'ant-design-vue'
import DayJS from 'dayjs'

import router from '@/router'

const isPlainObject = (target) =>
  target &&
  target.toString() === '[object Object]' &&
  Object.getPrototypeOf(target) === Object.prototype
const _jsonify = (target) => {
  if (target && typeof target.toFullJSON === 'function') { return target.toFullJSON() }
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    XIcon
  },

  setup () {
    const store = useStore()
    const ErrorDialog = ref()
    const SuccessDialog = ref()

    const state = reactive({
      allDealer: [],

      editOrAddOpen: false,
      editOrAdd: 'add',

      currentDealer: {
        name: '',
        phone: '',
        intro: ''
      }

    })

    console.log(state.allDealer)

    onMounted(async () => {
      state.allDealer = _jsonify(await new AV.Query('NBECDealer').limit(1000).find())
      console.log(state.allDealer)
      //   watchEffect(() => {
      //     console.log(state.currentDealer)
      //   })
    })

    const toAdd = async () => {
      state.editOrAddOpen = true
      state.editOrAdd = 'add'
      state.currentDealer = {
        name: '',
        dealer: '',
        intro: '',
        indexInt: 0,
        classify: state.grades[0]
      }
    }

    const editDealer = async (dealer) => {
      state.editOrAddOpen = true
      state.editOrAdd = 'edit'
      console.log(dealer)

      state.currentDealer = dealer
    }
    const save = async () => {
      console.log(state.currentDealer)
      // eslint-disable-next-line eqeqeq
      if (state.currentDealer.name.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '名字不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentDealer.phone.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '手机号码不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentDealer.intro.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '备注不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }

      // eslint-disable-next-line eqeqeq
      if (state.editOrAdd == 'edit' && state.currentDealer.objectId) {
        // 编辑
        const dealer = AV.parseJSON(JSON.parse(JSON.stringify(state.currentDealer)))
        dealer.set('name', state.currentDealer.name)
        dealer.set('intro', state.currentDealer.intro)
        dealer.set('phone', state.currentDealer.phone)
        try {
          await dealer.save()
          state.editOrAddOpen = false
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      } else {
        // 新增
        const dealer = new AV.Object('NBECDealer')
        // dealer=state.currentDealer
        dealer.set('name', state.currentDealer.name)
        dealer.set('intro', state.currentDealer.intro)
        dealer.set('phone', state.currentDealer.phone)
        dealer.set('validated', true)

        try {
          await dealer.save()
          state.allDealer.unshift(_jsonify(dealer))
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      }
    }

    const detail = (dealerId) => {
      router.push({
        name: 'DealerDetail',
        query: {
          dealerId
        }
      })
    }

    return {

      toAdd,
      save,
      detail,
      editDealer,
      ...toRefs(state),
      errorDoneAndNext: () => {
        ErrorDialog.value.hide()
      },
      ErrorDialog,
      SuccessDialog,
      doneAndNext: () => {
        SuccessDialog.value.hide()
      }

    }
  }
}
</script>
