<template>
  <error-dialog ref="ErrorDialog" title="抱歉" content="操作失败" confirmText="知道了"
    :doneAndNext="errorDoneAndNext"></error-dialog>
  <success-dialog ref="SuccessDialog" title="恭喜你" content="操作成功" confirmText="知道了"
    :doneAndNext="doneAndNext"></success-dialog>

  <div class="px-4  sm:px-6 lg:px-8">

    <div class="flex-row flex items-center px-4 py-4 bg-indigo-200  rounded">
      <button @click="toAdd" type="button" class=" inline-flex items-center px-2.5
            py-1.5
            border border-transparent
            text-xs
            font-medium
            rounded
            shadow-sm
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          ">
        新增人员
      </button>
      <xlsx-workbook v-if="dealer">
        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="dealer.name" />
        <!-- <xlsx-download>
        <button>Download</button>
      </xlsx-download> -->
        <xlsx-download disable-wrapper-click :filename="excelFileName" :options="excelOptions">
          <template #default="{ download }">
            <button @click="download" type="button" class="ml-10 inline-flex items-center px-2.5
            py-1.5
            border border-transparent
            text-xs
            font-medium
            rounded
            shadow-sm
            text-white
            bg-indigo-600
            hover:bg-indigo-700
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          ">
              下载所有人员
            </button>
          </template>
        </xlsx-download>
      </xlsx-workbook>

    </div>

    <a-table class="ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100" bordered
      :data-source="allUser" :scroll="{ y: 360 }" :rowClassName="(record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
        ">
      <a-table-column class="w-20" key="objectId" title="编号" :ellipsis="true" , data-index="objectId" />

      <a-table-column key="name" title="名字" data-index="name" />
      <a-table-column key="memberType" title="会员类型" data-index="memberType" />
      <a-table-column key="startDay" title="开始日期" data-index="startDay" />
      <a-table-column key="endDay" title="结束日期" data-index="endDay" />

      <a-table-column key="phone" title="手机号码" data-index="phone" />
      <a-table-column key="intro" title="备注" :ellipsis="true" data-index="intro" />
      <!--   <a-table-colum  key="intro" title="文本" class="w-30" :ellipsis="true" data-index="intro" />
        <a-table-column key="image" title="图片">
          <template #default="{ record }">
              <a :href="record.image" target="blank">{{record.imageName}}</a>
          </template>
        </a-table-column>
        -->

      <a-table-column key="action" title="操作">
        <template #default="{ record }">
          <span>
            <a-divider type="vertical" />
            <a @click="editDealer(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="deleteThis(record, index)">删除</a>
          </span>
        </template>
      </a-table-column>

      <template #footer>总共:<span class="text-indigo-600 px-2">{{ allUser.length }}</span>条记录</template>
    </a-table>
  </div>

  <TransitionRoot as="template" v-if="editOrAddOpen" :show="editOrAddOpen">
    <Dialog as="div" id="editOrAdd" v-if="editOrAddOpen" static class=" font-HLFont  fixed inset-0 z-10 overflow-hidden"
      @close="editOrAddOpen = false">
      <div class="absolute inset-0 overflow-hidden  font-HLFont">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full" enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
            leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                      {{ editOrAdd == 'edit' ? '编辑' : '新增' }}人员
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button"
                        class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        @click="editOrAddOpen = false">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-indigo-300">
                      请务必输入正确的姓名和手机号码
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->

                  <div class="absolute inset-0 py-6 px-4 sm:px-6">

                    <div class="h-full border-gray-200 py-5 px-5" aria-hidden="true">

                      <div>
                        <label for="name" class="
                                  block
                                  text-sm
                                  font-medium
                                  text-indigo-500
                                  group-hover:text-indigo-900
                                ">
                          姓名
                        </label>
                        <div class="mt-5">

                          <input id="name" type="text" name="name" v-model="currentUser.name"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="mt-10">

                        <label for="phone" class="
                                  block
                                  text-sm
                                  font-medium
                                  text-indigo-500
                                  group-hover:text-indigo-900
                                ">
                          手机号码<span v-if="editOrAdd == 'edit'" class="text-red-500">（手机号码不可编辑）</span>
                        </label>
                        <div class="mt-5">

                          <input :disabled="editOrAdd == 'edit'" id="phone" type="text" name="phone"
                            v-model="currentUser.phone"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>
                      <div class="mt-10">

                        <label for="startDay" class="
                                  block
                                  text-sm
                                  font-medium
                                  text-indigo-500
                                  group-hover:text-indigo-900
                                ">
                          设置会员开始日期<span class="text-red-500"></span>
                        </label>
                        <div class="mt-5">

                          <input id="startDay" type="date" :min="currentUser.startDay" name="startDay"
                            v-model="currentUser.startDay"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>
                      <div class="mt-10">

                        <label for="phone" class="
                              mb-7
                                  block
                                  text-sm
                                  font-medium
                                  text-indigo-500
                                  group-hover:text-indigo-900
                                ">
                          设置会员类型
                        </label>

                        <RadioGroup v-model="currentUser.memberType">

                          <div class="bg-white rounded-md -space-y-px">
                            <RadioGroupOption as="template" v-for="(memberType, memberTypeIdx) in memberTypes"
                              :key="memberType.name" :value="memberType" v-slot="{ checked, active }">
                              <div
                                :class="[memberTypeIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', memberTypeIdx === memberTypes.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                                <span
                                  :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']"
                                  aria-hidden="true">
                                  <span class="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <div class="ml-3 flex flex-row">
                                  <RadioGroupLabel as="span"
                                    :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                                    {{ memberType.name }}
                                  </RadioGroupLabel>
                                  <RadioGroupDescription as="span" class="ml-10"
                                    :class="[checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm']">
                                    {{ memberType.days }}天
                                  </RadioGroupDescription>
                                </div>
                              </div>
                            </RadioGroupOption>
                          </div>
                        </RadioGroup>
                      </div>

                      <div class="mt-10">
                        <label for="intro" class="
                                  block
                                  text-sm
                                  font-medium
                                  text-indigo-500
                                  group-hover:text-indigo-900
                                ">
                          备注
                        </label>
                        <div class="mt-1">

                          <textarea id="intro" name="intro" v-model="currentUser.intro"
                            class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 mt-10 border-t border-gray-200 py-5 sm:px-6">
                        <div class="space-x-3 flex justify-end">

                          <button @click="save"
                            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{ editOrAdd == 'edit' ? '保存' : '新增' }}
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- /End replace -->
                </div>
              </div>

            </div>

          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { computed, ref, reactive, toRefs, onMounted, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import AV from 'leancloud-storage'
import { notification } from 'ant-design-vue'
import DayJS from 'dayjs'
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload
} from 'vue3-xlsx'
import router from '@/router'

const isPlainObject = (target) =>
  target &&
  target.toString() === '[object Object]' &&
  Object.getPrototypeOf(target) === Object.prototype
const _jsonify = (target) => {
  if (target && typeof target.toFullJSON === 'function') { return target.toFullJSON() }
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}
const memberTypes = [
  { name: '月卡', days: 30 },
  { name: '季卡', days: 90 },
  { name: '半年卡', days: 180 },
  { name: '一年卡', days: 365 },
  { name: '二年卡', days: 730 },
  { name: '永久卡', days: 10950 }

]
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },

  setup () {
    // const store = useStore()
    const route = useRoute()
    const ErrorDialog = ref()
    const SuccessDialog = ref()
    // console.log(DayJS().format('YYYY-MM-DD'))
    const state = reactive({
      allUser: [],
      sheetName: null,
      sheets: [],
      excelFileName: '',
      excelOptions: {
        bookType: 'xlsx'
      },
      editOrAddOpen: false,
      editOrAdd: 'add',
      memberTypes,
      dealer: {},
      currentUser: {
        name: '',
        phone: '',
        intro: '',
        startDay: DayJS().format('YYYY-MM-DD'),
        memberType: memberTypes[0]
      }

    })

    state.dealerId = route.query.dealerId
    onMounted(async () => {
      state.dealer = await new AV.Query('NBECDealer').include('user').include('student').get(state.dealerId)

      state.allUser = _jsonify(await new AV.Query('NBECUserFromDealer').include('student').equalTo('dealer', state.dealer).limit(1000).find())

      state.excelFileName = '经销商-' + _jsonify(state.dealer).name + '.xlsx'

      watchEffect(() => {
        const data = []
        state.allUser.map(user => {
          data.push({
            姓名: user.name,
            手机号码: user.phone,
            会员类型: user.memberType,
            开始日期: user.startDay,
            结束日期: user.endDay,
            经销商名字: _jsonify(state.dealer).name
          })
        })
        state.sheets = [{ name: _jsonify(state.dealer).name, data }]
      })
    })

    const toAdd = async () => {
      state.editOrAddOpen = true
      state.editOrAdd = 'add'
      state.currentUser = {
        name: '',
        phone: '',
        intro: '',
        startDay: DayJS().format('YYYY-MM-DD'),
        memberType: memberTypes[0]
      }
    }

    const editDealer = async (dealer) => {
      state.editOrAddOpen = true
      state.editOrAdd = 'edit'
      // console.log(dealer)
      state.currentUser = JSON.parse(JSON.stringify(dealer))
      state.currentUser.memberType = memberTypes.find(memberType => {
        // console.log('dealer.memberType', dealer.memberType, ' memberType.name', memberType.name)
        // eslint-disable-next-line eqeqeq
        return memberType.name == dealer.memberType
      })
    }
    const save = async () => {
      console.log(state.currentUser)
      // eslint-disable-next-line eqeqeq
      if (state.currentUser.name.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '名字不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentUser.phone.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '手机号码不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentUser.startDay.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '开始日期不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      // if (state.currentUser.memberType.trim().length == 0) {
      //   notification.error({
      //     message: '错误',
      //     description: '开始日期不能为空',
      //     placement: 'bottomRight',
      //     duration: 5,
      //     onClose: () => {
      //       state.editOrAddOpen = true
      //     }

      //   })
      //   return
      // }

      // eslint-disable-next-line eqeqeq
      if (state.editOrAdd == 'edit' && state.currentUser.objectId) {
        console.log(state.currentUser)
        // 编辑
        const user = AV.parseJSON(JSON.parse(JSON.stringify(state.currentUser)))
        user.set('name', state.currentUser.name)
        user.set('intro', state.currentUser.intro)
        user.set('phone', state.currentUser.phone)
        user.set('startDay', state.currentUser.startDay)
        user.set('endDay', DayJS(state.currentUser.startDay).add(state.currentUser.memberType.days, 'days').format('YYYY-MM-DD'))
        user.set('memberType', state.currentUser.memberType.name)
        try {
          await user.save()
          const findIndex = state.allUser.findIndex(userTemp => {
            // eslint-disable-next-line eqeqeq
            return userTemp.objectId == state.currentUser.objectId
          })
          state.allUser.splice(findIndex, 1, _jsonify(user))
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      } else {
        // 新增
        const user = new AV.Object('NBECUserFromDealer')
        // dealer=state.currentUser
        user.set('name', state.currentUser.name)
        user.set('intro', state.currentUser.intro)
        user.set('phone', state.currentUser.phone)
        user.set('startDay', state.currentUser.startDay)
        user.set('memberType', state.currentUser.memberType.name)
        user.set('endDay', DayJS(state.currentUser.startDay).add(state.currentUser.memberType.days, 'days').format('YYYY-MM-DD'))
        user.set('dealer', state.dealer)
        user.set('validated', true)

        try {
          await user.save()
          state.allUser.unshift(_jsonify(user))
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      }
    }
    const deleteThis = async (user, index) => {
      if (user.student && user.user) {
        // 不能删除
        state.editOrAddOpen = false
        ErrorDialog.value.show()
      } else {
        const deleteUser = AV.parseJSON(JSON.parse(JSON.stringify(user)))
        try {
          await deleteUser.destroy()
          state.allUser.splice(index, 1)
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      }
    }
    const toDownload = async () => {

    }

    return {

      toAdd,
      save,
      deleteThis,
      editDealer,
      toDownload,
      ...toRefs(state),
      errorDoneAndNext: () => {
        ErrorDialog.value.hide()
      },
      ErrorDialog,
      SuccessDialog,
      doneAndNext: () => {
        SuccessDialog.value.hide()
      }

    }
  }
}
</script>
