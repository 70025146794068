<template>
    <error-dialog
    ref="ErrorDialog"
    title="抱歉"
    content="操作失败"
    confirmText="知道了"
    :doneAndNext="errorDoneAndNext"
  ></error-dialog>
  <success-dialog  ref="SuccessDialog" title="恭喜你" content="操作成功" confirmText="知道了" :doneAndNext="doneAndNext"></success-dialog>

  <div class="px-4  sm:px-6 lg:px-8">

    <div class="flex-row flex items-center px-4 py-4 bg-indigo-200  rounded">

      <button @click="toAdd" type="button" class=" inline-flex items-center px-2.5
          py-1.5
          border border-transparent
          text-xs
          font-medium
          rounded
          shadow-sm
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        ">
        新增题型
      </button>
    </div>

    <a-table v-if="allQuestionType.length>0" class="ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100" bordered :data-source="allQuestionType" :scroll="{ y: 350 }" :rowClassName="
        (record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
      ">
      <a-table-column class="w-20" key="objectId" title="编号" :ellipsis="true" , data-index="objectId" />
      <a-table-column key="series" title="排序" data-index="series" class="w-20" />
      <a-table-colum key="name" title="名称" class="w-30" :ellipsis="true" data-index="name" />
      <a-table-colum key="intro" title="备注" class="w-30" :ellipsis="true" data-index="intro" />
      <!-- <a-table-column key="image" title="图片">
          <template #default="{ record }">
              <a :href="record.image" target="blank">{{record.imageName}}</a>
          </template>
        </a-table-column>
        <a-table-column key="voice" title="音频">
          <template #default="{ record }">
            <a :href="record.voice" target="blank">{{record.voiceName}}</a>
          </template>
        </a-table-column> -->
      <!-- <a-table-column key="updatedAt" title="更新时间" data-index="updatedAt" /> -->
      <a-table-column key="action" title="操作">
        <template #default="{ record }">
          <span>
            <!-- <a @click="copyQuestion(record.objectId)">复用</a> -->
            <!-- <a-divider type="vertical" /> -->
            <a @click="editQuestionType(record)">编辑</a>
            <!-- <a-divider type="vertical" /> -->
            <!-- <a @click="deleteQuestion(record.objectId)">删除</a> -->
          </span>
        </template>
      </a-table-column>

      <template #footer>总共:<span class="text-indigo-600 px-2">{{allQuestionType.length}}</span>条记录</template>
    </a-table>
  </div>

  <TransitionRoot  as="template"  v-if="editOrAddOpen"  :show="editOrAddOpen">
    <Dialog as="div" id="editOrAdd" v-if="editOrAddOpen" static class="  font-HLFont fixed inset-0 z-10 overflow-hidden" @close="editOrAddOpen = false">
      <div class="absolute inset-0 overflow-hidden ">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-lg font-medium text-white">
                     {{editOrAdd=='edit'?'编辑':'新增'}}题型
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center">
                      <button type="button" class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="editOrAddOpen = false">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="mt-1">
                    <p class="text-sm text-indigo-300">
                      请描述完整题型操作方式
                    </p>
                  </div>
                </div>
                <div class="relative flex-1 py-6 px-4 sm:px-6">
                  <!-- Replace with your content -->

                  <div class="absolute inset-0 py-6 px-4 sm:px-6">

                    <div class="h-full border-2 border-dashed border-gray-200 py-5 px-5" aria-hidden="true">

                      <div>
                        <label for="name" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                          名称
                        </label>
                        <div class="mt-5">

                          <input id="name" type="text" name="name" v-model="currentQuestionType.name" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="mt-10">
                        <label for="intro" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                          备注
                        </label>
                        <div class="mt-5">

                          <textarea id="intro" name="intro" v-model="currentQuestionType.intro" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="mt-10">

                      <label for="intro" class="
                                block
                                text-sm
                                font-medium
                                text-indigo-500
                                group-hover:text-indigo-900
                              ">
                          排序
                        </label>
                        <div class="mt-5 border-b border-gray-300 focus-within:border-indigo-600">
                          <input type="number" name="series" id="series" v-model="currentQuestionType.series"   class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" />
                        </div>
                      </div>

                      <div class="flex-shrink-0 px-4 mt-10 border-t border-gray-200 py-5 sm:px-6">
                        <div class="space-x-3 flex justify-end">
                          <button @click="deleteThis" v-if="editOrAdd=='edit'" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            删除
                          </button>
                          <button  @click="save" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{editOrAdd=='edit'?'保存':'新增'}}
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                  <!-- /End replace -->
                </div>
              </div>

            </div>

          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import { computed, ref, reactive, toRefs, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import AV from 'leancloud-storage'
import { notification } from 'ant-design-vue'
import DayJS from 'dayjs'

import router from '@/router'

const isPlainObject = (target) =>
  target &&
  target.toString() === '[object Object]' &&
  Object.getPrototypeOf(target) === Object.prototype
const _jsonify = (target) => {
  if (target && typeof target.toFullJSON === 'function') { return target.toFullJSON() }
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon
  },

  setup () {
    const ErrorDialog = ref()
    const SuccessDialog = ref()
    const state = reactive({
      allQuestionType: [],
      editOrAddOpen: false,
      editOrAdd: 'add',
      currentQuestionType: {
        name: '',
        intro: '',
        series: 0
      }

    })

    onMounted(async () => {
      state.allQuestionType = _jsonify(await new AV.Query('FECQuestionType')
        .equalTo('validated', true)
        .ascending('series')
        .limit(1000)
        .find())
    })

    const toAdd = async () => {
      state.editOrAddOpen = true
      state.editOrAdd = 'add'
      state.currentQuestionType = {
        name: '',
        intro: '',
        series: 0
      }
    }

    const editQuestionType = async (questionType) => {
      state.editOrAddOpen = true
      state.editOrAdd = 'edit'
      console.log(questionType)

      state.currentQuestionType = questionType
    }
    const save = async () => {
      console.log(state.currentQuestionType)
      // eslint-disable-next-line eqeqeq
      if (state.currentQuestionType.name.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '名称不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentQuestionType.intro.trim().length == 0) {
        notification.error({
          message: '错误',
          description: '备注不能为空',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.currentQuestionType.series == 0) {
        notification.error({
          message: '错误',
          description: '排序不能小于等于0',
          placement: 'bottomRight',
          duration: 5,
          onClose: () => {
            state.editOrAddOpen = true
          }

        })
        return
      }
      // eslint-disable-next-line eqeqeq
      if (state.editOrAdd == 'edit' && state.currentQuestionType.objectId) {
        // 编辑
        const questionType = AV.parseJSON(JSON.parse(JSON.stringify(state.currentQuestionType)))
        questionType.set('intro', state.currentQuestionType.intro)
        questionType.set('name', state.currentQuestionType.name)
        questionType.set('series', Number(state.currentQuestionType.series))
        try {
          await questionType.save()
          state.editOrAddOpen = false
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      } else {
        // 新增
        const questionType = new AV.Object('FECQuestionType')
        // questionType=state.currentQuestionType
        questionType.set('intro', state.currentQuestionType.intro)
        questionType.set('description', state.currentQuestionType.intro)
        questionType.set('name', state.currentQuestionType.name)
        questionType.set('tips', state.currentQuestionType.name)
        questionType.set('series', Number(state.currentQuestionType.series))
        questionType.set('order', Number(state.currentQuestionType.series))
        questionType.set('validated', true)

        try {
          await questionType.save()
          state.allQuestionType.unshift(_jsonify(questionType))
          state.editOrAddOpen = false
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
          state.editOrAddOpen = false
          ErrorDialog.value.show()
        }
      }
    }

    return {

      toAdd,
      save,
      editQuestionType,
      ...toRefs(state),
      errorDoneAndNext: () => {
        ErrorDialog.value.hide()
      },
      ErrorDialog,
      SuccessDialog,
      doneAndNext: () => {
        SuccessDialog.value.hide()
      }

    }
  }
}
</script>
