<template>
  <error-dialog ref="ErrorDialog" title="抱歉" content="操作失败（或未查询到结果）" confirmText="知道了"
    :doneAndNext="errorDoneAndNext"></error-dialog>
  <success-dialog ref="SuccessDialog" title="恭喜你" content="操作成功" confirmText="知道了"
    :doneAndNext="doneAndNext"></success-dialog>

  <div class="px-4  sm:px-6 lg:px-8">

    <div class="flex-row flex items-center px-4 py-4 bg-indigo-200  rounded">
      <Listbox as="div" v-model="selectedLevel" class="flex-row flex items-center">
        <ListboxLabel class="text-sm font-medium text-indigo-700">
          等级
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
                                                                      relative
                                                                      w-48
                                                                      bg-white
                                                                      border border-gray-300
                                                                      rounded-md
                                                                      shadow-sm
                                                                      pl-3
                                                                      pr-10
                                                                      py-2
                                                                      text-left
                                                                      cursor-default
                                                                      focus:outline-none
                                                                      focus:ring-1 focus:ring-indigo-500
                                                                      focus:border-indigo-500
                                                                      sm:text-sm
                                                                    ">
            <span class="block truncate text-indigo-800">
              {{ selectedLevel.level.level }}-{{
                selectedLevel.level.name
              }}</span>
            <span class="
                                                                        absolute
                                                                        inset-y-0
                                                                        right-0
                                                                        flex
                                                                        items-center
                                                                        pr-2
                                                                        pointer-events-none
                                                                      ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions class="
                                                                        absolute
                                                                        z-10
                                                                        mt-1
                                                                        w-3/4
                                                                        bg-white
                                                                        shadow-lg
                                                                        max-h-60
                                                                        rounded-md
                                                                        py-1
                                                                        text-base
                                                                        ring-1 ring-black ring-opacity-5
                                                                        overflow-auto
                                                                        focus:outline-none
                                                                        sm:text-sm
                                                                      ">
              <ListboxOption as="template" v-for="level in allLevels" :key="level.objectId" :value="level"
                v-slot="{ active, selectedLevel }">
                <li :class="[
                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-8 pr-4',
                ]">
                  <span :class="[
                    selectedLevel ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]">
                    {{ level.level.level }}-{{ level.level.name }}
                  </span>

                  <span v-if="active" :class="[
                    active ? 'text-white' : 'text-indigo-600',
                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox as="div" v-model="selectedGrade" class="flex-row flex items-center">
        <ListboxLabel class="text-sm font-medium text-indigo-700">
          课堂
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
                                                                      relative
                                                                      w-48
                                                                      bg-white
                                                                      border border-gray-300
                                                                      rounded-md
                                                                      shadow-sm
                                                                      pl-3
                                                                      pr-10
                                                                      py-2
                                                                      text-left
                                                                      cursor-default
                                                                      focus:outline-none
                                                                      focus:ring-1 focus:ring-indigo-500
                                                                      focus:border-indigo-500
                                                                      sm:text-sm
                                                                    ">
            <span class="block truncate text-indigo-800">{{
              selectedGrade.showName
            }}</span>
            <span class="
                                                                        absolute
                                                                        inset-y-0
                                                                        right-0
                                                                        flex
                                                                        items-center
                                                                        pr-2
                                                                        pointer-events-none
                                                                      ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions class="
                                                                        absolute
                                                                        z-10
                                                                        mt-1
                                                                        w-3/4
                                                                        bg-white
                                                                        shadow-lg
                                                                        max-h-60
                                                                        rounded-md
                                                                        py-1
                                                                        text-base
                                                                        ring-1 ring-black ring-opacity-5
                                                                        overflow-auto
                                                                        focus:outline-none
                                                                        sm:text-sm
                                                                      ">
              <ListboxOption as="template" v-for="grade in allGrades" :key="grade.objectId" :value="grade"
                v-slot="{ active }">
                <li :class="[
                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-8 pr-4',
                ]">
                  <span :class="[
                    active ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]">
                    {{ grade.showName }}
                  </span>

                  <span v-if="active" :class="[
                    active ? 'text-white' : 'text-indigo-600',
                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox as="div" v-model="selectedDay" class="flex-row flex items-center">
        <ListboxLabel class="text-sm font-medium text-indigo-700">
          天数
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
                                                                      relative
                                                                      bg-white
                                                                      border border-gray-300
                                                                      rounded-md
                                                                      shadow-sm
                                                                      pl-3
                                                                      pr-10
                                                                      py-2
                                                                      text-left
                                                                      cursor-default
                                                                      focus:outline-none
                                                                      focus:ring-1 focus:ring-indigo-500
                                                                      focus:border-indigo-500
                                                                      sm:text-sm
                                                                    ">
            <span class="block truncate text-indigo-800">{{
              selectedDay
            }}</span>
            <span class="
                                                                        absolute
                                                                        inset-y-0
                                                                        right-0
                                                                        flex
                                                                        items-center
                                                                        pr-2
                                                                        pointer-events-none
                                                                      ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions class="
                                                                        absolute
                                                                        z-10
                                                                        mt-1
                                                                        bg-white
                                                                        shadow-lg
                                                                        max-h-60
                                                                        rounded-md
                                                                        py-1
                                                                        text-base
                                                                        ring-1 ring-black ring-opacity-5
                                                                        overflow-auto
                                                                        focus:outline-none
                                                                        sm:text-sm
                                                                      ">
              <ListboxOption as="template" v-for="day in allDays" :key="day" :value="day"
                v-slot="{ active, selectedDay }">
                <li :class="[
                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-8 pr-4',
                ]">
                  <span :class="[
                    selectedDay ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]">
                    {{ day }}
                  </span>

                  <span v-if="active" :class="[
                    active ? 'text-white' : 'text-indigo-600',
                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox as="div" v-model="selectedQuestionType" class="flex-row flex items-center">
        <ListboxLabel class="text-sm w-30 font-medium text-indigo-700">
          题型
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
                                                                      relative
                                                                      bg-white
                                                                      border border-gray-300
                                                                      rounded-md
                                                                      shadow-sm
                                                                      pl-3
                                                                      pr-10
                                                                      py-2
                                                                      text-left
                                                                      cursor-default
                                                                      focus:outline-none
                                                                      focus:ring-1 focus:ring-indigo-500
                                                                      focus:border-indigo-500
                                                                      sm:text-sm
                                                                    ">
            <span class="block truncate text-indigo-800">{{
              selectedQuestionType.name
            }}</span>
            <span class="
                                                                        absolute
                                                                        inset-y-0
                                                                        right-0
                                                                        flex
                                                                        items-center
                                                                        pr-2
                                                                        pointer-events-none
                                                                      ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions class="
                                                                        absolute
                                                                        z-10
                                                                        mt-1
                                                                        w-3/4
                                                                        bg-white
                                                                        shadow-lg
                                                                        max-h-60
                                                                        rounded-md
                                                                        py-1
                                                                        text-base
                                                                        ring-1 ring-black ring-opacity-5
                                                                        overflow-auto
                                                                        focus:outline-none
                                                                        sm:text-sm
                                                                      ">
              <ListboxOption as="template" v-for="questionType in allQuestionType" :key="questionType.objectId"
                :value="questionType" v-slot="{ active, selectedQuestionType }">
                <li :class="[
                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                  'cursor-default select-none relative py-2 pl-8 pr-4',
                ]">
                  <span :class="[
                    selectedQuestionType ? 'font-semibold' : 'font-normal',
                    'block truncate',
                  ]">
                    {{ questionType.name }}
                  </span>

                  <span v-if="active" :class="[
                    active ? 'text-white' : 'text-indigo-600',
                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <button type="button" @click="search" class="
                                                                  inline-flex
                                                                  items-center
                                                                  px-2.5
                                                                  py-1.5
                                                                  mx-3
                                                                  border border-transparent
                                                                  text-xs
                                                                  font-medium
                                                                  rounded
                                                                  shadow-sm
                                                                  text-white
                                                                  bg-indigo-600
                                                                  hover:bg-indigo-700
                                                                  focus:outline-none
                                                                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                                                                ">
        查询
      </button>

      <button v-if="allAssociate.length > 0" type="button" @click="deleteAll" class="
                                                                  inline-flex
                                                                  items-center
                                                                  px-2.5
                                                                  py-1.5
                                                                  mx-3
                                                                  border border-transparent
                                                                  text-xs
                                                                  font-medium
                                                                  rounded
                                                                  shadow-sm
                                                                  text-white
                                                                  bg-indigo-600
                                                                  hover:bg-indigo-700
                                                                  focus:outline-none
                                                                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                                                                ">
        下架全部
      </button>

      <button v-if="allAssociate.length == 0 && selectedDay != '全部' && selectedQuestionType.name != '全部题型'" type="button"
        @click="addQuestionTypeInGrade" class="
                                                                  inline-flex
                                                                  items-center
                                                                  px-2.5
                                                                  py-1.5
                                                                  mx-3
                                                                  border border-transparent
                                                                  text-xs
                                                                  font-medium
                                                                  rounded
                                                                  shadow-sm
                                                                  text-white
                                                                  bg-indigo-600
                                                                  hover:bg-indigo-700
                                                                  focus:outline-none
                                                                  focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                                                                ">
        上架该新题型
      </button>

    </div>

    <a-table v-if="allAssociate.length > 0" :rowKey="record"
      class="ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100" bordered
      :data-source="allAssociate" :scroll="{ y: 350 }" :rowClassName="
        (record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
      ">
      <a-table-column class="w-30" key="objectId" title="编号" :ellipsis="true" , data-index="objectId" />
      <a-table-column key="grade" title="等级" data-index="grade">
        <template #default="{ record }">
          {{ record.grade.level }}-{{ record.grade.name }}-{{ record.grade.showName }}
        </template>
      </a-table-column>
      <a-table-colum key="anyDay" title="天数" class="w-30" data-index="anyDay" />
      <a-table-column key="questionType" title="题型">
        <template #default="{ record }">
          {{ record.questionType.name }}
        </template>
      </a-table-column>
      <a-table-column key="updatedAt" title="更新时间" data-index="updatedAt" />
      <a-table-column key="action" title="操作">
        <template #default="{ record, index }">
          <span class="">
            <a @click="deleteThis(record, index)" class="text-red-500">下架</a>
          </span>
        </template>
      </a-table-column>
      <template #footer>总共:<span class="text-indigo-600 px-2">{{ allAssociate.length }}</span>条记录</template>
    </a-table>
  </div>
</template>

<script>
import { computed, ref, reactive, toRefs, onMounted, watchEffect, createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import { Modal } from 'ant-design-vue'
import DayJS from 'dayjs'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

const allDays = []
for (let i = 1; i < 101; i++) {
  allDays.push(i)
}
allDays.unshift('全部')
const indexInOneDays = []
for (let i = 1; i < 101; i++) {
  indexInOneDays.push(i)
}

const _jsonify = (target) => {
  if (target && typeof target.toFullJSON === 'function') {
    const fullJson = target.toFullJSON()
    fullJson.createdAt = DayJS(fullJson.createdAt).format('YYYY-MM-DD HH:mm:ss')
    fullJson.updatedAt = DayJS(fullJson.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    return fullJson
  }
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}
const getAllQuestionType = async () => {
  return await new AV.Query('FECQuestionType').equalTo('validated', true).ascending('series').find()
}

export default {
  components: {

    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon
  },

  setup () {
    const store = useStore()

    const SuccessDialog = ref()
    const ErrorDialog = ref()
    const state = reactive({
      allLevels: [],
      editOrAddOpen: false,
      selectedLevel: localStorage.selectedLevel ? JSON.parse(localStorage.selectedLevel) : {
        level: {
          name: '',
          level: ''
        }
      },
      allGrades: [],
      selectedGrade: localStorage.selectedGrade ? JSON.parse(localStorage.selectedGrade) : {
        showName: ''
      },
      selectedDay: '全部',
      allQuestionType: [],
      selectedQuestionType: { name: '全部题型' },
      allAssociate: [],
      currentEditQuestion: {
      }
      // indexInOneDay: 1
    })

    onMounted(async () => {
      const levelsTemp = _jsonify(
        await new AV.Query('FECGrade')
          .equalTo('validated', true)
          .ascending('series')
          .limit(1000)
          .find()
      )

      state.allLevels = []
      levelsTemp.map((levelTemp) => {
        const findIndex = state.allLevels.findIndex((level) => {
          // eslint-disable-next-line eqeqeq
          return level.level && level.level.series == levelTemp.series
        })

        // eslint-disable-next-line eqeqeq
        if (findIndex == -1) {
          state.allLevels.push({
            level: levelTemp,
            grades: [levelTemp]
          })
        } else {
          state.allLevels[findIndex].grades.push(levelTemp)
        }
      })

      store.state.allLevels = state.allLevels
      if (!localStorage.selectedLevel) {
        state.selectedLevel = state.allLevels[3]
      }

      state.allGrades = state.selectedLevel.grades.sort((a, b) => {
        return a.classify - b.classify
      })
      // console.log(JSON.parse(localStorage.selectedGrade))
      if (!localStorage.selectedGrade) {
        state.selectedGrade = state.allGrades[0]
      }

      state.allQuestionType = _jsonify(await getAllQuestionType())
      state.allQuestionType.unshift({ name: '全部题型' })
      store.state.allQuestionType = state.allQuestionType
      if (!localStorage.selectedQuestionType) {
        state.selectedQuestionType = state.allQuestionType[0]
      }
      // console.log(state)
      watchEffect(() => {
        state.allGrades = state.selectedLevel.grades.sort((a, b) => {
          return a.classify - b.classify
        })
        // console.log(state.allGrades)
        state.selectedGrade = state.allGrades[0]
      })
    })

    const search = async () => {
      // console.log(AV.parseJSON(JSON.parse(JSON.stringify(state.selectedPaper))))

      // state.allAssociate =
      const query = new AV.Query('NBECQuestionTypeInGrade')
        .equalTo(
          'grade',
          AV.parseJSON(JSON.parse(JSON.stringify(state.selectedGrade)))
        )
      if (state.selectedQuestionType.name !== '全部题型') {
        query.equalTo(
          'questionType',
          AV.parseJSON(JSON.parse(JSON.stringify(state.selectedQuestionType)))
        )
      } else {
        const validateQuestionType = new AV.Query('FECQuestionType')
        validateQuestionType.equalTo('validated', true)
        query.matchesQuery('questionType', validateQuestionType)
      }
      if (state.selectedDay !== '全部') {
        query.equalTo('anyDay', Number(state.selectedDay))
      }
      console.log(query)

      state.allAssociate = _jsonify(
        await query.ascending('anyDay')
          .include('grade')
          .include('questionType')
          .equalTo('validated', true)
          .limit(1000)
          .find()

      )
      if (state.allAssociate.length == 0) {
        ErrorDialog.value.show()
      }
      console.log(state.allAssociate)
    }
    const deleteAll = async () => {
      Modal.confirm({
        title: '提示',
        content: '是否删除全部题型对应关系',
        async onOk () {
          for (let i = 0; i < state.allAssociate.length; i++) {
            const deleteOne = AV.parseJSON(state.allAssociate[i])
            deleteOne.set('validated', false)
            await deleteOne.save()
            // await deleteOne.destroy()
          }
          state.allAssociate = []
          SuccessDialog.value.show()
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }

    const deleteThis = async (record, index) => {
      Modal.confirm({
        title: '提示',
        content: '是否删除当前题型对应关系',
        async onOk () {
          const deleteOne = AV.parseJSON(record)
          deleteOne.set('validated', false)
          await deleteOne.save()
          // await deleteOne.destroy()
          state.allAssociate = state.allAssociate.filter(questionTypeAndGrade => {
            return questionTypeAndGrade.objectId !== record.objectId
          })
          SuccessDialog.value.show()
        },
        onCancel () {
          console.log('Cancel')
        }
      })

      //   state.editOrAddOpen = false
      //   ErrorDialog.value.show()
    }

    const addQuestionTypeInGrade = async () => {
      if (state.selectedDay === '全部') {
        alert('请选择具体天数')
        return
      }
      if (state.selectedQuestionType.name === '全部题型') {
        alert('请选择具体题型')
        return
      }

      const grade = AV.parseJSON(JSON.parse(JSON.stringify(state.selectedGrade)))
      const selectDay = Number(state.selectedDay)

      const questionType = AV.parseJSON(JSON.parse(JSON.stringify(state.selectedQuestionType)))

      var questionTypeInGrade = await new AV.Query('NBECQuestionTypeInGrade').equalTo('anyDay', selectDay).equalTo('grade', grade).equalTo('questionType', questionType).first()

      if (questionTypeInGrade) {
        questionTypeInGrade.set('validated', true)
        await questionTypeInGrade.save()
        state.allAssociate.push(_jsonify(questionTypeInGrade))
        SuccessDialog.value.show()
      } else {
        questionTypeInGrade = new AV.Object('NBECQuestionTypeInGrade')
        questionTypeInGrade.set('anyDay', selectDay)
        questionTypeInGrade.set('grade', grade)
        questionTypeInGrade.set('questionType', questionType)
        try {
          await questionTypeInGrade.save()
          state.allAssociate.push(_jsonify(questionTypeInGrade))
          SuccessDialog.value.show()
        } catch (e) {
          console.log(e)
        }
      }
    }
    return {
      // allPaperCategory,

      search,
      addQuestionTypeInGrade,
      indexInOneDays,
      allDays,

      deleteThis,
      deleteAll,

      ...toRefs(state),
      errorDoneAndNext: () => {
        ErrorDialog.value.hide()
      },
      ErrorDialog,
      SuccessDialog,
      doneAndNext: () => {
        SuccessDialog.value.hide()
      }
    }
  }
}

</script>
<style scoped>
/* audio::-webkit-media-controls-fullscreen-button {
    display: none;
}

audio::-webkit-media-controls-play-button {
    display: none;
} */

audio::-webkit-media-controls-timeline {
  display: none;
}

audio::-webkit-media-controls-current-time-display {
  display: none;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

audio::-webkit-media-controls-mute-button {
  display: none;
}
audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}

audio::-webkit-media-controls-volume-slider {
  display: none;
}
/*
audio::-webkit-media-controls-enclosure{
    display: none;
} */
</style>
