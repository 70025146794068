<template>
    <error-dialog
    ref="ErrorDialog"
    title="抱歉"
    content="操作失败"
    confirmText="知道了"
    :doneAndNext="errorDoneAndNext"
  ></error-dialog>
  <success-dialog  ref="SuccessDialog" title="恭喜你" content="操作成功" confirmText="知道了" :doneAndNext="doneAndNext"></success-dialog>

  <div class="px-4  sm:px-6 lg:px-8">

    <div class="flex-row flex items-center px-4 py-4 bg-indigo-200  rounded">
      <Listbox as="div" v-model="selectedLevel" class="flex-row flex items-center">
        <ListboxLabel class="text-sm font-medium text-indigo-700">
          等级
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
              relative
              w-48
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
            <span class="block truncate text-indigo-800">
              {{ selectedLevel.level.level }}-{{
                selectedLevel.level.name
              }}</span>
            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
              <ListboxOption as="template" v-for="level in allLevels" :key="level.objectId" :value="level" v-slot="{ active, selectedLevel }">
                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                  <span :class="[
                      selectedLevel ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                    {{ level.level.level }}-{{ level.level.name }}
                  </span>

                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox as="div" v-model="selectedGrade" class="flex-row flex items-center">
        <ListboxLabel class="text-sm font-medium text-indigo-700">
          课堂
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
              relative
              w-48
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
            <span class="block truncate text-indigo-800">{{
              selectedGrade.showName
            }}</span>
            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
              <ListboxOption as="template" v-for="grade in allGrades" :key="grade.objectId" :value="grade" v-slot="{ active }">
                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                  <span :class="[
                      active ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                    {{ grade.showName }}
                  </span>

                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox as="div" v-model="selectedDay" class="flex-row flex items-center">
        <ListboxLabel class="text-sm font-medium text-indigo-700">
         天数
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
            <span class="block truncate text-indigo-800">{{
              selectedDay
            }}</span>
            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="
                absolute
                z-10
                mt-1
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
              <ListboxOption as="template" v-for="day in allDays" :key="day" :value="day" v-slot="{ active, selectedDay }">
                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                  <span :class="[
                      selectedDay ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                    {{ day }}
                  </span>

                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <Listbox as="div" v-model="selectedQuestionType" class="flex-row flex items-center">
        <ListboxLabel class="text-sm w-30 font-medium text-indigo-700">
          题型
        </ListboxLabel>
        <div class="mt-1 relative px-10">
          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
            <span class="block truncate text-indigo-800">{{
              selectedQuestionType.name
            }}</span>
            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
              <ListboxOption as="template" v-for="questionType in allQuestionType" :key="questionType.objectId" :value="questionType" v-slot="{ active, selectedQuestionType }">
                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                  <span :class="[
                      selectedQuestionType ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                    {{ questionType.name }}
                  </span>

                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>

      <button type="button" @click="search" class="
          inline-flex
          items-center
          px-2.5
          py-1.5
          mx-3
          border border-transparent
          text-xs
          font-medium
          rounded
          shadow-sm
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        ">
        查询
      </button>
          <button @click="toAdd" type="button" class=" inline-flex items-center px-2.5
          py-1.5
          border border-transparent
          text-xs
          font-medium
          rounded
          shadow-sm
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        ">
        新增题目
      </button>
    </div>

    <a-table  v-if="allQuestion.length>0" class="ant-table-striped  scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100"  bordered :data-source="allQuestion" :scroll="{ y: 350 }" :rowClassName="
        (record, index) => (index % 2 === 1 ? 'bg-gray-50' : 'bg-white')
      ">
      <a-table-column class="w-20" key="objectId" title="编号" :ellipsis="true" , data-index="objectId" />
      <a-table-column key="indexInOneDay" title="排序" data-index="indexInOneDay" class="w-20" />
       <a-table-colum  key="intro" title="文本" class="w-30" :ellipsis="true" data-index="intro" />
        <a-table-column key="image" title="图片">
          <template #default="{ record }">
              <a :href="record.image" target="blank">{{record.imageName}}</a>
          </template>
        </a-table-column>
        <a-table-column key="voice" title="音频">
          <template #default="{ record }">
            <a :href="record.voice" target="blank">{{record.voiceName}}</a>
          </template>
        </a-table-column>
      <!-- <a-table-column key="updatedAt" title="更新时间" data-index="updatedAt" /> -->
      <a-table-column key="action" title="操作">
        <template #default="{ record ,index}">
          <span class="">
            <a @click="copyQuestion(record.objectId)">复用</a>
            <a-divider type="vertical" />
            <a @click="editQuestion(record.objectId)">编辑</a>
              <a-divider type="vertical" />
            <a @click="quickSetQuestion(index)" class="text-indigo-700">快速设置</a>
              <a-divider type="vertical" />
            <a @click="deleteQuestion(index)" class="text-red-500">删除</a>
          </span>
        </template>
      </a-table-column>

    <template #footer>总共:<span class="text-indigo-600 px-2">{{allQuestion.length}}</span>条记录</template>
    </a-table>
  </div>

  <TransitionRoot as="template" v-if="editOrAddOpen" :show="editOrAddOpen">
    <Dialog as="div"  v-if=" editOrAddOpen"  auto-reopen="true" class="fixed inset-0 overflow-hidden w-1 ">
      <div class="absolute inset-0 overflow-hidden">
        <!-- <DialogOverlay class="absolute inset-0" /> -->
        <!-- -->
        <div class=" fixed inset-y-0 pl-16   flex font-HLFont right-0 z-10 ">
          <TransitionChild as="template">
            <div class="w-screen max-w-md ">
              <div class="
                    h-full
                    divide-y divide-gray-200
                    flex flex-col
                    bg-white
                    shadow-xl
                  ">

             <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle  class="text-lg font-medium text-white">
                        快速设置

                      </DialogTitle>

                      <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="
                              bg-indigo-700
                              rounded-md
                              text-indigo-200
                              hover:text-white
                              focus:outline-none
                              focus:ring-2 focus:ring-white
                            " @click="editOrAddOpen = false">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-indigo-300">
                       当天排序、等级、天数、题型等
                      </p>

                    </div>
                  </div>

                    <div class="flex-col flex  px-10 py-10 bg-indigo-200 ">
                      <!-- <Listbox as="div" v-model="selectedQuestionType" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm w-30 font-medium text-indigo-700 mb-10">
                          题型
                        </ListboxLabel>
                        <div class="mt-1 relative px-10 mb-10">
                          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              selectedQuestionType.name
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="questionType in allQuestionType" :key="questionType.objectId" :value="questionType" v-slot="{ active, selectedQuestionType }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedQuestionType ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ questionType.name }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                      <Listbox as="div" v-model="selectedLevel" class="flex-row flex items-center ">
                        <ListboxLabel class="text-sm font-medium text-indigo-700  mb-10">
                          等级
                        </ListboxLabel>
                        <div class="mt-1 relative px-10  mb-10">
                          <ListboxButton class="
              relative
              w-48
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">
                              {{ selectedLevel.level.level }}-{{
                selectedLevel.level.name
              }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="level in allLevels" :key="level.objectId" :value="level" v-slot="{ active, selectedLevel }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedLevel ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ level.level.level }}-{{ level.level.name }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>

                      <Listbox as="div" v-model="selectedGrade" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm font-medium text-indigo-700  mb-10">
                          课堂
                        </ListboxLabel>
                        <div class="mt-1 relative px-10  mb-10">
                          <ListboxButton class="
              relative
              w-48
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              selectedGrade.showName
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                w-3/4
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="grade in allGrades" :key="grade.objectId" :value="grade" v-slot="{ active, selectedGrade }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedGrade ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ grade.showName }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>

                      <Listbox as="div" v-model="selectedDay" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm font-medium text-indigo-700 mb-10">
                          天数
                        </ListboxLabel>
                        <div class="mt-1 relative px-10 mb-10">
                          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm
              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              selectedDay
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="day in allDays" :key="day" :value="day" v-slot="{ active, selectedDay }">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                      selectedDay ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ day }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox> -->

                      <Listbox as="div" v-model="currentEditQuestion.indexInOneDay" class="flex-row flex items-center">
                        <ListboxLabel class="text-sm font-medium text-indigo-700">
                          排序
                        </ListboxLabel>
                        <div class="mt-1 relative px-10  ">
                          <ListboxButton class="
              relative
              bg-white
              border border-gray-300
              rounded-md
              shadow-sm

              pl-3
              pr-10
              py-2
              text-left
              cursor-default
              focus:outline-none
              focus:ring-1 focus:ring-indigo-500
              focus:border-indigo-500
              sm:text-sm
            ">
                            <span class="block truncate text-indigo-800">{{
              currentEditQuestion.indexInOneDay
            }}</span>
                            <span class="
                absolute
                inset-y-0
                right-0
                flex
                items-center
                pr-2
                pointer-events-none
              ">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="
                absolute
                z-10
                mt-1
                bg-white
                shadow-lg
                max-h-60
                rounded-md
                py-1
                text-base
                ring-1 ring-black ring-opacity-5
                overflow-auto
                focus:outline-none
                sm:text-sm
              ">
                              <ListboxOption as="template" v-for="day in indexInOneDays" :key="day" :value="day" v-slot="{ active, currentEditQuestion}">
                                <li :class="[
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-8 pr-4',
                  ]">
                                  <span :class="[
                     active ? 'font-semibold' : 'font-normal',
                      'block truncate',
                    ]">
                                    {{ day }}
                                  </span>

                                  <span v-if="active" :class="[
                      active ? 'text-white' : 'text-indigo-600',
                      'absolute inset-y-0 left-0 flex items-center pl-1.5',
                    ]">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>

                    </div>
                    <div class="mt-10">

                      <button @click="saveQuestion" class="
                        ml-4
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      ">
                        保存当前设置到数据库
                      </button>
                    </div>
                  </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
    </TransitionRoot>

</template>

<script>
import { computed, ref, reactive, toRefs, onMounted, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AV from 'leancloud-storage'
import DayJS from 'dayjs'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions, Switch, SwitchDescription, SwitchGroup, SwitchLabel, Dialog, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, DialogTitle, TransitionChild, TransitionRoot
} from '@headlessui/vue'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import { XIcon } from '@heroicons/vue/outline'
import router from '@/router'
const allDays = []
for (let i = 1; i < 101; i++) {
  allDays.push(i)
}
const indexInOneDays = []
for (let i = 1; i < 101; i++) {
  indexInOneDays.push(i)
}

const isPlainObject = (target) =>
  target &&
  target.toString() === '[object Object]' &&
  Object.getPrototypeOf(target) === Object.prototype
const _jsonify = (target) => {
  if (target && typeof target.toFullJSON === 'function') { return target.toFullJSON() }
  if (Array.isArray(target)) return target.map(_jsonify)
  return target
}

const getAllQuestionType = async () => {
  return await new AV.Query('FECQuestionType').equalTo('validated', true).ascending('series').find()
}

export default {
  components: {
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot,

    XIcon,

    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon
  },

  setup () {
    console.log('setup')
    const store = useStore()

    const SuccessDialog = ref()
    const ErrorDialog = ref()
    const state = reactive({
      allLevels: [],
      editOrAddOpen: false,
      selectedLevel: localStorage.selectedLevel ? JSON.parse(localStorage.selectedLevel) : {
        level: {
          name: '',
          level: ''
        }
      },
      allGrades: [],
      selectedGrade: localStorage.selectedGrade ? JSON.parse(localStorage.selectedGrade) : {
        showName: ''
      },
      selectedDay: localStorage.anyDay ? Number(localStorage.anyDay) : 1,
      allQuestionType: [],
      selectedQuestionType: localStorage.selectedQuestionType ? JSON.parse(localStorage.selectedQuestionType) : { name: '' },
      allQuestion: [],
      currentEditQuestion: {
      }
      // indexInOneDay: 1
    })

    onMounted(async () => {
      const levelsTemp = _jsonify(
        await new AV.Query('FECGrade')
          .ascending('series')
          .limit(1000)
          .find()
      )

      state.allLevels = []
      levelsTemp.map((levelTemp) => {
        const findIndex = state.allLevels.findIndex((level) => {
          // eslint-disable-next-line eqeqeq
          return level.level && level.level.series == levelTemp.series
        })

        // eslint-disable-next-line eqeqeq
        if (findIndex == -1) {
          state.allLevels.push({
            level: levelTemp,
            grades: [levelTemp]
          })
        } else {
          state.allLevels[findIndex].grades.push(levelTemp)
        }
      })

      store.state.allLevels = state.allLevels
      if (!localStorage.selectedLevel) {
        state.selectedLevel = state.allLevels[3]
      }

      state.allGrades = state.selectedLevel.grades.sort((a, b) => {
        return a.classify - b.classify
      })
      // console.log(JSON.parse(localStorage.selectedGrade))
      if (!localStorage.selectedGrade) {
        state.selectedGrade = state.allGrades[0]
      }

      state.allQuestionType = _jsonify(await getAllQuestionType())

      store.state.allQuestionType = state.allQuestionType
      if (!localStorage.selectedQuestionType) {
        state.selectedQuestionType = state.allQuestionType[0]
      }
      // console.log(state)
      watchEffect(() => {
        state.allGrades = state.selectedLevel.grades.sort((a, b) => {
          return a.classify - b.classify
        })
        // console.log(state.allGrades)
        state.selectedGrade = state.allGrades[0]
      })
    })

    const search = async () => {
      // console.log(AV.parseJSON(JSON.parse(JSON.stringify(state.selectedPaper))))

      // state.allQuestion =
      state.allQuestion = []

      const allQuestionTemp = _jsonify(
        await new AV.Query('FECQuestion')
          .equalTo('anyDay', Number(state.selectedDay))
          .equalTo(
            'grade',
            AV.parseJSON(JSON.parse(JSON.stringify(state.selectedGrade)))
          )
          .equalTo(
            'questionType',
            AV.parseJSON(JSON.parse(JSON.stringify(state.selectedQuestionType)))
          )
          .ascending('indexInOneDay')
          .limit(1000)
          .find()

      )
      localStorage.anyDay = state.selectedDay
      localStorage.selectedGrade = JSON.stringify(state.selectedGrade)
      localStorage.selectedQuestionType = JSON.stringify(state.selectedQuestionType)
      localStorage.selectedLevel = JSON.stringify(state.selectedLevel)

      state.allQuestionTemp = allQuestionTemp
      allQuestionTemp.map((questionTemp) => {
        state.allQuestion.push({
          objectId: questionTemp.objectId,
          indexInOneDay: questionTemp.indexInOneDay,
          intro: questionTemp.questionData[0][0].intro,
          image: questionTemp.questionData[0][0].image.url,
          imageName: questionTemp.questionData[0][0].image.name,
          imageInfo: questionTemp.questionData[0][0].image.url ? questionTemp.questionData[0][0].image.url + '?imageInfo' : '',
          voice: questionTemp.questionData[0][0].voice.url,
          voiceName: questionTemp.questionData[0][0].voice.name,
          createdAt: DayJS(questionTemp.createdAt).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          updatedAt: DayJS(questionTemp.updatedAt).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        })
      })
    }
    const toAdd = async () => {
      router.push({
        name: 'QuestionAdd',
        query: {
          editType: 'add',
          questionId: ''
        }
      })
    }

    const editQuestion = async (questionId) => {
      console.log('questionId', questionId)
      router.push({
        name: 'QuestionAdd',
        query: {
          questionId,
          editType: 'edit'
        }
      })
    }

    const copyQuestion = async (questionId) => {
      console.log('questionId', questionId)
      router.push({
        name: 'QuestionAdd',
        query: {
          questionId,
          editType: 'copy'
        }
      })
    }

    const quickSetQuestion = async (index) => {
      console.log(index)
      state.editOrAddOpen = true
      // const currentEditQuestion =
      state.currentEditQuestionAV = state.allQuestionTemp[index]
      state.currentEditQuestion = state.allQuestion[index]

      // console.log(state.currentEditQuestion)
      // const currentEditQUestionJSON
      // console.log(currentEditQuestion)
      // state.indexInOneDay = currentEditQuestion.indexInOneDay

      // state.selectedDay = currentEditQuestion.anyDay
      // state.selectedQuestionType = currentEditQuestion.questionType
      // state.selectedLevel = currentEditQuestion.grade

      // let questionJSON=
    }
    const saveQuestion = async () => {
      // AV.parseJSON(state.currentEditQuestionAV)
      // state.currentEditQuestionAV.indexInOneDay = state.currentEditQuestion.indexInOneDay
      const unSaveQuestion = AV.parseJSON(state.currentEditQuestionAV)
      unSaveQuestion.set('indexInOneDay', state.currentEditQuestion.indexInOneDay)
      console.log(state.currentEditQuestionAV.indexInOneDay)
      try {
        await unSaveQuestion.save()
        state.editOrAddOpen = false
        SuccessDialog.value.show()
      } catch (e) {
        console.log(e)
        state.editOrAddOpen = false
        ErrorDialog.value.show()
      }
    }
    const deleteQuestion = async (index) => {
      state.editOrAddOpen = false
      ErrorDialog.value.show()
    }
    return {
      // allPaperCategory,

      search,
      toAdd,
      indexInOneDays,
      allDays,
      editQuestion,
      copyQuestion,
      quickSetQuestion,
      deleteQuestion,
      saveQuestion,
      ...toRefs(state),
      errorDoneAndNext: () => {
        ErrorDialog.value.hide()
      },
      ErrorDialog,
      SuccessDialog,
      doneAndNext: () => {
        SuccessDialog.value.hide()
      }
    }
  }
}
</script>
<style scoped>
/* audio::-webkit-media-controls-fullscreen-button {
    display: none;
}

audio::-webkit-media-controls-play-button {
    display: none;
} */

audio::-webkit-media-controls-timeline {
  display: none;
}

audio::-webkit-media-controls-current-time-display {
  display: none;
}

audio::-webkit-media-controls-time-remaining-display {
  display: none;
}

audio::-webkit-media-controls-mute-button {
  display: none;
}
audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}

audio::-webkit-media-controls-volume-slider {
  display: none;
}
/*
audio::-webkit-media-controls-enclosure{
    display: none;
} */
</style>
